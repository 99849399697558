import 'react-toastify/dist/ReactToastify.css';
import 'src/Toast.scss';

import {
  Token,
  UserType,
  commonReducers,
  featureDecisions,
  getAccessTokenPayload,
  getCurrentUrl,
  tokenExpiresSoon,
} from '@polygence/common';
import * as backendApi from '@polygence/common/api/backend';
import * as marketplaceApi from '@polygence/common/api/marketplace';
import * as siteContentApi from '@polygence/common/api/site-content';
import * as websocketApi from '@polygence/common/websocket/api';
import { ScrollToTopButton } from '@polygence/components';
import * as Sentry from '@sentry/react';
import { Component, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Router, withRouter } from 'react-router';
import { Link, Route, Switch } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import snakecase from 'snakecase-keys';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';

import { AcceptPolicyModal } from 'src/AcceptPolicyModal';
import CertificatePage from 'src/CertificatePage';
import VersionChecker from 'src/VersionChecker';
import AddInterestTags from 'src/components/AddInterestTags';
import { StudentCollegeAdmissionSurvey } from 'src/components/CollegeAdmissionSurvey/StudentCollegeAdmissionSurvey';
import { DiscourseRedirect } from 'src/components/DiscourseRedirect';
import PressReleases from 'src/components/EditableContent/PressReleases';
import PublicPosts from 'src/components/EditableContent/PublicPosts';
import Resources from 'src/components/EditableContent/Resources';
import ErrorPage from 'src/components/ErrorPage';
import GeneralRoutes from 'src/components/GeneralRoutes.lazy';
import { Guidebook, ProjectSupportVideoPage } from 'src/components/Guidebook';
import MainRoutes from 'src/components/MainRoutes.lazy';
import { MobileRedirect } from 'src/components/MobileRedirect';
import NoMatchPage from 'src/components/NoMatchPage';
import { PageLoad } from 'src/components/PageLoad';
import ReferralRoutes from 'src/components/ReferralRoutes.lazy';
import { SettingsRoutes } from 'src/components/SettingsRoutes';
import SiteNavigationBar from 'src/components/SiteNavigationBar';
import StudentRoutes from 'src/components/StudentRoutes.lazy';
import FeedbackRoutes from 'src/components/TeachingFeedback/FeedbackRoutes.lazy';
import AdminRoutes from 'src/components/admin/AdminRoutes.lazy';
import { UnverifiedUnauthorizedPage } from 'src/components/auth/UnverifiedUnauthorizedPage/UnverifiedUnauthorizedPage';
import { PrivateRoute, StudentRoute } from 'src/components/authenticatedRoutes';
import DateWrapper from 'src/components/aux/dateWrapper';
import showToast from 'src/components/aux/showToast';
import { Tracker } from 'src/components/common/Tracker';
import { CounselorBanner } from 'src/components/counselor/CounselorBanner';
import CounselorRoutes from 'src/components/counselor/CounselorRoutes.lazy';
import { getMyInfo } from 'src/components/getMyInfo';
import MentorRoutes from 'src/components/mentor/MentorRoutes.lazy';
import NotionPageRouter from 'src/components/notion/NotionPageRouter.lazy';
import ParentRoutes from 'src/components/parent/ParentRoutes.lazy';
import { ProjectIdeaGenerator } from 'src/components/projectIdeaGenerator/ProjectIdeaGenerator';
import Recordings from 'src/components/recordings/Recordings.lazy';
import { SignedURLs } from 'src/components/specialURLs/SignedURLs';
import ScholarFilteredListPage from 'src/components/student/ScholarPage/ScholarFilteredListPage';
import ScholarPage from 'src/components/student/ScholarPage/ScholarPage';
import { PathfinderApplicationInterimPage } from 'src/components/student/pathfinders/PathfinderApplicationInterimPage';
import { SOCIAL_MEDIA_BANNER } from 'src/constants';
import { history } from 'src/history';
import { AppHeight } from 'src/hooks/useAppHeight';
import { OneTimeAccessPaymentMethodPage } from 'src/payment/OneTimeAccessPaymentMethodPage';
import { PaymentIntentPage } from 'src/payment/PaymentIntent/PaymentIntentPage';
import { PaymentSuccessPage } from 'src/payment/PaymentSuccess/PaymentSuccessPage';
import { store } from 'src/store';
import { DisabledPartnerAlert } from 'src/students/DisabledPartnerAlert';
import { PodPage } from 'src/students/Pods/PodPage';
import { urls } from 'src/urls';
import { ImpersonatorNotice } from 'src/user-switcher/ImpersonatorNotice';
import {
  deleteGlobalCookie,
  getAccessToken,
  getQueryParams,
  getRefreshToken,
  getToken,
  isImpersonator,
  removeAuthenticationTokens,
  setAccessToken,
  setGlobalCookie,
  setRefreshToken,
  userNameFromToken,
} from 'src/utils';
import { Clarity } from 'src/utils/Clarity';
import DisableNumberInputScroll from 'src/utils/DisableNumberInputScroll';
import { isClientTimezoneDifferent } from 'src/utils/compareTimezones';
import { studentApplicationLocalStorageHandler } from 'src/utils/localStorageManager';
import { getAfterSignInUrl } from 'src/utils/navigation';
import { getTracker } from 'src/utils/tracking/factory';

class App extends Component {
  constructor(props) {
    super();
    this.state = {
      appLoaded: false,
      userDataLoading: false,
      userDataLoaded: false,
      profile_approved: false,
      missing_desired_fields_list: [],
      screened: false,
      show_policy: true,
      pendingPolicy: null,
      showPolicyModal: false,
      eligibleForReferral: false,
      mentorAvailable: null,
      profileTimezone: undefined,
      symposiumPresenter: false,
      polyGptEnabled: false,
    };
    this.getUserData = this.getUserData.bind(this);
  }

  getUserData(callback) {
    const { userType } = this.props;
    this.setState({ userDataLoading: true });
    return marketplaceApi
      .getUserInfo()
      .then(({ data }) => {
        store.dispatch(
          commonReducers.userActions.loadUser({
            ...data,
            token: getAccessToken(),
          }),
        );
        return snakecase(data, { deep: true });
      })
      .then((result) => {
        this.setState(({ opportunitiesEnabled }) => ({
          userDataLoaded: true,
          userDataLoading: false,
          profile_approved: result.approved,
          missing_desired_fields_list: result.missing_desired_fields_list,
          screened: result.screened,
          pendingPolicy: result.pending_policies.length > 0 ? result.pending_policies[0] : null,
          showPolicyModal: result.pending_policies.length > 0 && !isImpersonator(),
          eligibleForReferral: result.eligible_for_referral,
          referralUrl: result.referral_url,
          opportunitiesEnabled: opportunitiesEnabled || result.opportunities_enabled,
          hasActiveProject: result.has_active_project,
          mentorAvailable: result.other_info.available,
          discordEnabled: result.discord_enabled,
          scholarGroup: result.scholar_group,
          hermesEnabled: result.hermes_enabled,
          admissionAdvisorEmail: result.other_info.admission_advisor_email,
          enrolledAt: result.other_info.enrolled_at,
          scholarshipApplicant: result.other_info.scholarship_applicant,
          scholarPageSlug: result.other_info.scholar_page_slug,
          profileTimezone: result.time_zone ? result.time_zone : undefined,
          symposiumPresenter: result.other_info.symposium_presenter,
          studentJourneyTileSections: result.other_info.student_journey_tile_sections,
          isPartnerDisabled: result.other_info.is_partner_disabled,
          partnerCompanyName: result.other_info.partner_company_name,
        }));

        if (typeof callback === 'function') {
          callback();
        }
        if (
          userType === 'student' &&
          result.has_active_project &&
          result.other_info?.demography_data &&
          Object.keys(result.other_info.demography_data).length === 0
        ) {
          store.dispatch(commonReducers.userActions.updateUser({ missingDemographyData: true }));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  async componentDidMount() {
    const { loggedIn, login, logout } = this.props;
    const accessTokenPayload = getAccessTokenPayload(getToken(Token.Access));
    if (accessTokenPayload) {
      login(accessTokenPayload);
      if (tokenExpiresSoon(accessTokenPayload)) {
        const refreshToken = getRefreshToken();
        try {
          const result = await backendApi.refreshToken(refreshToken);
          setAccessToken(result.data.access);
          login(getAccessTokenPayload(getToken(Token.Access)));
        } catch (error) {
          console.error('Token expired during refresh operation.');
          logout({ purgeAuthTokens: true });
        }
      }
      this.getUserData();
      const featureDecisionsInstance = await featureDecisions.getInstance();
      this.setState(
        ({
          opportunitiesEnabled,
          hermesEnabled,
          scholarPageEnabled,
          pathfindersEnabled,
          applicationJourneyEnabled,
          polyGptEnabled,
        }) => ({
          opportunitiesEnabled:
            opportunitiesEnabled || featureDecisionsInstance.opportunityDashboardEnabled(),
          hermesEnabled: hermesEnabled || featureDecisionsInstance.hermesEnabled(),
          scholarPageEnabled: scholarPageEnabled || featureDecisionsInstance.scholarPageEnabled(),
          pathfindersEnabled: pathfindersEnabled || featureDecisionsInstance.pathfindersEnabled(),
          applicationJourneyEnabled:
            applicationJourneyEnabled || featureDecisionsInstance.applicationJourneyEnabled(),
          appLoaded: true,
        }),
      );
    } else if (loggedIn) {
      logout();
      this.setState({ appLoaded: true });
    } else {
      backendApi
        .trackAnonymousUser(document.referrer, {
          ...getQueryParams(window.location.search),
          url: getCurrentUrl(window.location.href),
        })
        .then(() => {
          return backendApi.getAnonymousUserFeatures();
        })
        .finally(() => {
          this.setState({ appLoaded: true });
        });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { userDataLoaded } = this.state;
    if (prevState.userDataLoaded !== userDataLoaded) {
      this.displayToasts();
    }
  }

  displayToasts = () => {
    const {
      profile_approved: profileApproved,
      missing_desired_fields_list: missingDesiredFieldsList,
      mentorAvailable,
      userDataLoaded,
      profileTimezone,
    } = this.state;
    const { userType, loggedIn } = this.props;
    const isProfileIncomplete =
      profileApproved &&
      ['student', 'mentor'].includes(userType) &&
      missingDesiredFieldsList !== undefined &&
      missingDesiredFieldsList.length !== 0;
    const isMentorUnavailable = userType === 'mentor' && mentorAvailable === false;

    if (
      loggedIn &&
      userDataLoaded &&
      !window.location.href.includes('user-settings') &&
      !window.location.href.includes('review-student')
    ) {
      if (isProfileIncomplete) {
        toast.warning(
          <p>
            Missing profile information.
            <br />
            Please <Link to="/user-settings">update</Link> your profile.
          </p>,
          { autoClose: false, toastId: 'missing-profile-info' },
        );
      }
      if (isMentorUnavailable && showToast('mentor unavailable', 10)) {
        toast(
          <p>
            You have marked yourself unavailable. Please update your availability{' '}
            <Link to="/user-settings">here</Link>.
          </p>,
          { autoClose: false },
        );
      }
    }

    if (loggedIn && userDataLoaded && isClientTimezoneDifferent(profileTimezone)) {
      store.dispatch(commonReducers.userActions.updateUser({ timeZoneMismatch: true }));
    }
  };

  handle_login = async (json, callback) => {
    const { login } = this.props;
    setAccessToken(json.access);
    setRefreshToken(json.refresh);
    const featureDecisionsInstance = await featureDecisions.getInstance();
    this.setState(
      ({
        opportunitiesEnabled,
        hermesEnabled,
        scholarPageEnabled,
        pathfindersEnabled,
        applicationJourneyEnabled,
        polyGptEnabled,
      }) => ({
        opportunitiesEnabled:
          opportunitiesEnabled || featureDecisionsInstance.opportunityDashboardEnabled(),
        hermesEnabled: hermesEnabled || featureDecisionsInstance.hermesEnabled(),
        scholarPageEnabled: scholarPageEnabled || featureDecisionsInstance.scholarPageEnabled(),
        pathfindersEnabled: pathfindersEnabled || featureDecisionsInstance.pathfindersEnabled(),
        applicationJourneyEnabled:
          applicationJourneyEnabled || featureDecisionsInstance.applicationJourneyEnabled(),
      }),
    );
    login(getAccessTokenPayload(json.access));
    await this.getUserData(() => {
      if (typeof callback === 'function') {
        callback();
      }
    });
    const myInfo = getMyInfo();
    if (!myInfo.time_zone) {
      marketplaceApi.updateUserProfile(
        { timeZone: DateWrapper.guessTimezone(), partial: true },
        myInfo.user_type,
      );
    }
    setGlobalCookie('loggedInFirstname', myInfo.first_name);
    getTracker().identify(myInfo.user_id);
    const { location: { state: { from = undefined } = {} } = {} } = history;
    history.push(from || getAfterSignInUrl());
  };

  handle_logout = () => {
    const { logout } = this.props;
    logout({ purgeAuthTokens: true, clearLocalStorage: true, deleteLoggedInCookie: true });
    history.push(urls.login());
  };

  switch_off_modal = () => {
    this.setState({
      show_policy: false,
    });
  };

  handlePolicyAccept() {
    const { pendingPolicy } = this.state;
    const closeModalAndClearPolicy = () =>
      this.setState({ showPolicyModal: false, pendingPolicy: null });

    siteContentApi
      .acceptPolicy(pendingPolicy.pk)
      .then(closeModalAndClearPolicy, closeModalAndClearPolicy);
  }

  handlePolicyReject() {
    const { pendingPolicy } = this.state;
    const closeModalAndClearPolicy = () =>
      this.setState({ showPolicyModal: false, pendingPolicy: null });

    siteContentApi
      .rejectPolicy(pendingPolicy.pk)
      .then(closeModalAndClearPolicy, closeModalAndClearPolicy);
  }

  render() {
    const {
      appLoaded,
      profile_approved: profileApproved,
      screened,
      eligibleForReferral,
      hermesEnabled,
      hasActiveProject,
      discordEnabled,
      scholarPageEnabled,
      scholarGroup,
      pendingPolicy,
      show_policy: showPolicy,
      showPolicyModal,
      opportunitiesEnabled,
      admissionAdvisorEmail,
      referralUrl,
      missing_desired_fields_list: missingDesiredFieldsList,
      userDataLoaded,
      userDataLoading,
      enrolledAt,
      scholarshipApplicant,
      scholarPageSlug,
      symposiumPresenter,
      pathfindersEnabled,
      studentJourneyTileSections,
      applicationJourneyEnabled,
      polyGptEnabled,
      isPartnerDisabled,
      partnerCompanyName,
    } = this.state;
    const { userType, channel, loggedIn } = this.props;

    if (!appLoaded || userDataLoading || (loggedIn && !userDataLoaded)) {
      return <PageLoad />;
    }

    if (userDataLoaded && userType === 'student' && isPartnerDisabled) {
      return <DisabledPartnerAlert partnerName={partnerCompanyName} />;
    }

    const title = 'Polygence - Online Research Opportunities for High School Students';
    const description =
      'Polygence is a research academy dedicated to providing research opportunities to high school students through a rigorous online program.';
    return (
      <Router history={history}>
        <QueryParamProvider adapter={ReactRouter5Adapter}>
          <ToastContainer
            position="top-right"
            autoClose={2500}
            hideProgressBar
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            className="hide-in-print"
            theme="colored"
            icon={false}
          />
          <Tracker />
          <VersionChecker />
          <AppHeight />
          <DisableNumberInputScroll />
          {!window.Playwright && <Clarity />}

          {isImpersonator() && <ImpersonatorNotice />}
          <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta
              name="keywords"
              content="virtual,online,research,opportunities,program,high school,students,science,summer"
            />
            <meta property="og:image" content={SOCIAL_MEDIA_BANNER} />
            <meta property="og:url" content="https://www.polygence.org" />
            <meta property="og:type" content="website" />
          </Helmet>
          <SiteNavigationBar
            profileApproved={profileApproved}
            screened={screened}
            handleLogout={this.handle_logout}
            eligibleForReferral={eligibleForReferral}
            hermesEnabled={hermesEnabled}
            hasActiveProject={hasActiveProject}
            discordEnabled={discordEnabled}
            scholarPageEnabled={scholarPageEnabled}
            scholarGroup={scholarGroup}
            symposiumPresenter={symposiumPresenter}
            polyGptEnabled={polyGptEnabled}
          />
          <CounselorBanner />
          <Suspense fallback={<PageLoad />}>
            {pendingPolicy && showPolicy ? (
              <AcceptPolicyModal
                policy={pendingPolicy}
                isOpen={showPolicyModal}
                onAccept={this.handlePolicyAccept.bind(this)}
                onReject={this.handlePolicyReject.bind(this)}
              />
            ) : null}
            <Switch>
              <PrivateRoute
                path="/dashboard"
                component={GeneralRoutes}
                handleLogout={this.handle_logout}
                profileApproved={profileApproved}
                screened={screened}
                eligibleForReferral={eligibleForReferral}
                opportunitiesEnabled={opportunitiesEnabled}
                scholarPageEnabled={scholarPageEnabled}
                hasActiveProject={hasActiveProject}
                admissionAdvisorEmail={admissionAdvisorEmail}
                enrolledAt={enrolledAt}
                scholarshipApplicant={scholarshipApplicant}
                scholarPageSlug={scholarPageSlug}
                symposiumPresenter={symposiumPresenter}
                pathfindersEnabled={pathfindersEnabled}
                studentJourneyTileSections={studentJourneyTileSections}
              />
              <PrivateRoute
                path="/home"
                component={MentorRoutes}
                switch_off_modal={this.switch_off_modal}
                logged_in={loggedIn}
              />
              <PrivateRoute
                path="/mentor-guidebook"
                exact
                component={Guidebook}
                userTypes={[UserType.MENTOR]}
                title="Welcome to Mentor Guidebook!"
              />
              <PrivateRoute
                path="/student-guidebook"
                exact
                component={Guidebook}
                userTypes={[UserType.STUDENT]}
                title="Welcome to Student Guidebook!"
              />
              <PrivateRoute
                path="/project-support-video/:slug"
                exact
                component={ProjectSupportVideoPage}
              />
              <StudentRoute
                path="/student"
                userType={userType}
                component={StudentRoutes}
                loggedIn={loggedIn}
                channel={channel}
                applicationJourneyEnabled={applicationJourneyEnabled}
              />
              <Route path="/scholars/peers/:url" exact component={ScholarFilteredListPage} />
              <Route path="/scholars/:slug" exact component={ScholarPage} />
              <Route
                path="/counselor"
                render={(props) => <CounselorRoutes logged_in={loggedIn} />}
              />
              <Route path="/parent" render={(props) => <ParentRoutes logged_in={loggedIn} />} />
              <Route path="/administrator" render={(props) => <AdminRoutes />} />

              <Route path="/resources" render={(props) => <Resources logged_in={loggedIn} />} />

              <Route path="/n">
                <NotionPageRouter />
              </Route>

              <Route
                path="/press-releases"
                render={(props) => <PressReleases logged_in={loggedIn} />}
              />
              <Route path="/postings" render={(props) => <PublicPosts logged_in={loggedIn} />} />

              <Route path="/recordings" render={(props) => <Recordings logged_in={loggedIn} />} />

              <Route path="/teaching" render={(props) => <FeedbackRoutes logged_in={loggedIn} />} />

              <Route path="/signed-urls" render={(props) => <SignedURLs />} />

              <Route
                path="/referral"
                render={(props) => (
                  <ReferralRoutes
                    referralUrl={referralUrl}
                    loggedIn={loggedIn}
                    userType={userType}
                  />
                )}
              />

              <PrivateRoute
                path="/user-settings"
                component={SettingsRoutes}
                logged_in={loggedIn}
                missing_desired_fields_list={missingDesiredFieldsList}
              />
              <PrivateRoute path="/pods" component={PodPage} />
              <PrivateRoute
                path="/college-admission-survey"
                component={StudentCollegeAdmissionSurvey}
              />

              <Route path="/404" component={NoMatchPage} />

              <Route path="/add-interest-tags" component={AddInterestTags} />

              <Route path="/error-page" component={ErrorPage} />
              <Route path="/unverified-unauthorized" component={UnverifiedUnauthorizedPage} />
              <Route path="/payment/success/:uuid">
                <PaymentSuccessPage />
              </Route>
              <Route exact path="/payment/:uuid">
                <PaymentIntentPage />
              </Route>
              <Route path="/pathfinders-submission/:applicationUuid">
                <PathfinderApplicationInterimPage />
              </Route>
              <Route path="/customer-portal/:uuid">
                <OneTimeAccessPaymentMethodPage />
              </Route>
              <Route path="/certificates/:certificateUuid">
                <CertificatePage />
              </Route>
              <Route path="/login-from-mobile">
                <MobileRedirect />
              </Route>
              <Route
                path="/discourse-sso"
                render={(props) => <DiscourseRedirect {...props} loggedIn={loggedIn} />}
              />
              <PrivateRoute
                path="/project-idea-generator"
                component={appLoaded ? ProjectIdeaGenerator : PageLoad}
                logged_in={loggedIn}
              />

              <Route
                path="/"
                render={(props) => (
                  <MainRoutes
                    logged_in={loggedIn}
                    handle_login={this.handle_login}
                    user_type={userType}
                  />
                )}
              />
            </Switch>
          </Suspense>
          <ScrollToTopButton />
        </QueryParamProvider>
      </Router>
    );
  }
}

const mapStateToProps = (state) => {
  const { user } = state;
  const { loggedIn, userType, channel } = user;
  return { loggedIn, userType, channel };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    login: (accessTokenPayload) => {
      Sentry.setUser({
        id: accessTokenPayload.user_id,
        email: accessTokenPayload.email,
        name: userNameFromToken(accessTokenPayload),
        ip_address: '{{auto}}',
      });
    },
    logout: ({
      purgeAuthTokens = false,
      clearLocalStorage = false,
      deleteLoggedInCookie = false,
    } = {}) => {
      studentApplicationLocalStorageHandler.remove();
      featureDecisions.clearInstance();

      if (clearLocalStorage) {
        localStorage.clear();
      }
      if (purgeAuthTokens) {
        removeAuthenticationTokens();
      }
      if (deleteLoggedInCookie) {
        deleteGlobalCookie('loggedInFirstname');
      }

      websocketApi.closeConnection();

      Sentry.configureScope((scope) => scope.setUser(null));
      dispatch(commonReducers.userActions.removeUser());
    },
  };
};
// eslint-disable-next-line import/no-default-export -- autodisabled
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
