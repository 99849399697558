import type { CamelCasedPropertiesDeep } from 'type-fest';

import type {
  CartItemSerializer,
  CartSerializer,
  PaymentIntentInvoiceSerializer,
  PaymentIntentProductsSerializer,
  StripeCustomerSerializer,
  DiscountSerializer,
  CartAdminSerializer,
  PaymentIntentReadOnlyAdminSerializer,
  CartItemAdminSerializer,
  RefundSerializer,
  CartUserDataVerificationSerializer,
  CartDiscountSerializer,
} from './data/payment';

export const enum PaymentIntentOrdering {
  CREATED_AT_ASC = 'created_at',
  CREATED_AT_DESC = '-created_at',
  FIRST_PAYMENT_RECEIVED_AT_ASC = 'first_payment_received_at',
  FIRST_PAYMENT_RECEIVED_AT_DESC = '-first_payment_received_at',
  NEXT_DUE_ASC = 'next_due_at',
  NEXT_DUE_DESC = '-next_due_at',
  PAYMENT_FAILED_AT_ASC = 'payment_failed_at',
  PAYMENT_FAILED_AT_DESC = '-payment_failed_at',
}

export const enum PaymentIntentTypes {
  ONE_TIME = 'one_time',
  SUBSCRIPTION = 'subscription',
}

export const enum PaymentIntentTargets {
  PROJECT = 'project',
  ADDITIONAL_SESSION = 'additional_session',
  ADDITIONAL_WRITING_FEEDBACK = 'additional_writing_feedback',
  SHOWCASING_SUPPORT_ADDON = 'showcasing_support_addon',
  POD = 'pod',
  OTHER = 'other',
  PATHFINDER = 'pathfinder',
  POLYPILOT = 'polypilot',
}

export const enum PaymentIntentStatuses {
  REQUESTED = 'requested',
  PENDING = 'pending',
  PAID = 'paid',
  CANCELED = 'canceled',
  REFUNDED = 'refunded',
}

export const enum ProductNames {
  Core = 'Flagship project',
}

export type PaymentIntentInvoice = CamelCasedPropertiesDeep<PaymentIntentInvoiceSerializer>;
export type PaymentIntentProducts = CamelCasedPropertiesDeep<PaymentIntentProductsSerializer>;
export type StripeCustomer = CamelCasedPropertiesDeep<StripeCustomerSerializer>;
export type CartItem = CamelCasedPropertiesDeep<CartItemSerializer>;
export type CartDiscount = CamelCasedPropertiesDeep<CartDiscountSerializer>;
export type CartUserDataVerification = CamelCasedPropertiesDeep<CartUserDataVerificationSerializer>;
export type Cart = CamelCasedPropertiesDeep<CartSerializer>;
export type CartItemAdmin = CamelCasedPropertiesDeep<CartItemAdminSerializer>;
export type CartAdmin = CamelCasedPropertiesDeep<CartAdminSerializer>;
export type Discount = CamelCasedPropertiesDeep<DiscountSerializer>;
export type PaymentIntentReadOnlyAdmin =
  CamelCasedPropertiesDeep<PaymentIntentReadOnlyAdminSerializer>;
export type Refund = CamelCasedPropertiesDeep<RefundSerializer>;
