/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import * as hermesApi from '../../api/hermes';
import type { User } from '../../types/backend';
import type { HermesAIMessageThreadId, RoomId, TodoItemId, WorkspaceId } from '../../types/common';
import { ErrorInPayload, isErrorInPayload } from '../../types/data/common';
import type {
  MessageSerializer,
  SessionSerializer,
  SummarySerializer,
  WorkspaceSerializer,
} from '../../types/data/hermes';
import type { PermanentFileSerializer } from '../../types/data/marketplace';
import {
  Assignment,
  AssignmentBlueprint,
  AssignmentSolution,
  CancelSessionRequest,
  Lounge,
  Meeting,
  Message,
  MessageType,
  Project,
  Room,
  ScheduleSession,
  ScheduledSession,
  Session,
  SessionSummary,
  SessionSummaryPayload,
  SessionsForProject,
  SharedResource,
  StudentFeedbackResponse,
  Workspace,
  WorkspaceDisplay,
} from '../../types/hermes';
import type { TodoItem } from '../../types/todo';
import type { ByIds, Modal, Nullable } from '../../types/utils';
import { dayjs } from '../../utils/dayjsCustom';
import {
  HermesMobileTab,
  defaultMobileNavigationTab,
} from '../../utils/hermes/hermesMobileNavigation';
import { unsentMessagesLocalStorageHandler } from '../../utils/hermes/unsentMessagesStorageManager';
import type { RootState } from '../store';

export const getWorkspaceDisplayList = createAsyncThunk<WorkspaceDisplay[]>(
  'hermes/getWorkspaceDisplayList',
  async () => {
    const { data } = await hermesApi.getWorkspaceDisplayList();
    return data;
  },
);

export const getWorkspaceById = createAsyncThunk<
  WorkspaceSerializer | null,
  { workspaceId: WorkspaceId },
  { state: RootState }
>('hermes/getWorkspaceById', async ({ workspaceId }, { getState }) => {
  const state = getState();

  if (state.hermes.workspaces.byId[+workspaceId]) {
    return Promise.resolve(null);
  }

  const response = await hermesApi.getWorkspaceById(workspaceId);
  return response.data;
});

export const getSessionsForProject = createAsyncThunk<SessionsForProject, { projectId: number }>(
  'hermes/getSessionsForProject',
  async ({ projectId }) => {
    const response = await hermesApi.getSessionsForProject(projectId);
    const sessions = response.data;
    return { sessions, projectId };
  },
);

export const getMeetingsForWorkspace = createAsyncThunk<
  MeetingsForProject,
  { workspaceId: number }
>('hermes/getMeetingsForWorkspace', async ({ workspaceId }) => {
  const response = await hermesApi.getMeetingsForWorkspace(workspaceId);
  const meetings = response.data;
  return { meetings, workspaceId };
});

export const getTodoItems = createAsyncThunk('hermes/getTodoItems', async () => {
  const response = await hermesApi.getTodoItems();
  return response.data;
});

export const updateTodoItem = createAsyncThunk<
  TodoItem,
  { id: TodoItemId; payload: Partial<TodoItem> }
>('hermes/updateTodoItem', async ({ id, payload }) => {
  const response = await hermesApi.updateTodoItem(id, payload);
  return response.data;
});

export const markCompletedTodoItemsAsSeen = createAsyncThunk<
  TodoItemId[],
  { todoItemIds: TodoItemId[] }
>('hermes/markCompletedTodoItemsAsSeen', async ({ todoItemIds }) => {
  await hermesApi.markCompletedTodoItemsAsSeen(todoItemIds);
  return todoItemIds;
});

export const saveSessionForProject = createAsyncThunk<
  ErrorInPayload | ScheduledSession,
  { projectId: number; sessionId: number } & Partial<Session>
>('hermes/createSessionForProject', async ({ projectId, sessionId, ...payload }) => {
  if (sessionId && sessionId !== -1) {
    const response = await hermesApi.updateSessionForProject(projectId, sessionId, payload);
    return response.data;
  }
  const response = await hermesApi.createSessionForProject(projectId, payload);
  return response.data;
});

export const saveMeeting = createAsyncThunk<
  ErrorInPayload | MessageSerializer,
  { meetingId: Nullable<number | undefined> } & Partial<Meeting>
>('hermes/saveMeeting', async ({ meetingId, ...payload }) => {
  if (meetingId && meetingId !== -1) {
    const response = await hermesApi.updateMeeting(meetingId, payload);
    return response.data;
  }
  const response = await hermesApi.createMeeting(payload);
  return response.data;
});

export const cancelMeeting = createAsyncThunk<ErrorInPayload, { meetingId: number }>(
  'hermes/cancelMeeting',
  async ({ meetingId }) => {
    const response = await hermesApi.cancelMeeting(meetingId);
    return response.data;
  },
);

export const cancelSession = createAsyncThunk<
  ErrorInPayload | ScheduledSession,
  CancelSessionRequest
>(
  'hermes/cancelSession',
  async ({ projectId, sessionId, reason, proposalDeclineReason, declined = false, isLate }) => {
    const response = await hermesApi.cancelSession({
      projectId,
      sessionId,
      reason,
      proposalDeclineReason,
      declined,
      isLate,
    });
    if (!isLate) {
      await hermesApi.deleteSessionOfProject(projectId, sessionId);
    }
    return response.data;
  },
);

export const updateProject = createAsyncThunk(
  'hermes/updateProject',
  async ({ projectId, ...payload }: { projectId: number } & Partial<Project>) => {
    const response = await hermesApi.updateProject(projectId, payload);
    return response.data;
  },
);

export const getProject = createAsyncThunk<Project, { id: number }>(
  'hermes/getProject',
  async ({ id }) => {
    const response = await hermesApi.getProject(id);
    return response.data;
  },
);

/* eslint-disable no-console */
export const getMessagesForRoom = createAsyncThunk<
  MessagesForRoom,
  { roomId: number; page: number }
>('hermes/getMessagesForRoom', async ({ roomId, page }) => {
  try {
    const result = await hermesApi.getMessagesForRoom(roomId, page);
    const messages = result.data;
    return { roomId, messages: messages.results };
  } catch (err) {
    console.error(err);
  }
  return { roomId, messages: [] };
});

export const submitSessionSummary = createAsyncThunk<
  ErrorInPayload | SummarySerializer,
  SessionSummary
>('hermes/submitSessionSummary', async ({ projectId, sessionId, ...data }) => {
  const { preparationLength, content = '', assignment = '', ...extras } = data;
  const payload: SessionSummaryPayload = {
    preparationLength: Number(preparationLength),
    content,
    assignment,
    extras,
    session: sessionId,
  };
  const result = await hermesApi.submitSessionSummary(projectId, sessionId, payload);
  return result.data;
});

export const submitStudentFeedback = createAsyncThunk(
  'hermes/submitStudentFeedback',
  async (data: { content: Record<string, unknown>; session: number }) => {
    return await hermesApi.submitStudentFeedback(data);
  },
);

export const submitStudentFeedbackRequest = createAsyncThunk<
  StudentFeedbackResponse,
  {
    projectId: number;
    userId: number;
    fileStyleGuide: PermanentFileSerializer[];
    [k: string]: unknown;
  }
>('hermes/submitStudentFeedbackRequest', async ({ projectId, userId, fileStyleGuide, ...data }) => {
  const payload: StudentFeedbackResponse = {
    user: userId,
    content: {
      ...data,
    },
    fileStyleGuide,
    project: projectId,
  };
  const result = await hermesApi.submitStudentFeedbackRequest(payload);
  return result.data;
});

export const getAssignmentBlueprints = createAsyncThunk<
  AssignmentBlueprint[],
  { workspaceId: number }
>('hermes/getAssignments', async ({ workspaceId }) => {
  const result = await hermesApi.getAssignmentBlueprints(workspaceId);
  return result.data;
});

export const uploadAssignment = createAsyncThunk<
  { nextPage: string },
  { projectId: number; nextPage: string }
>('hermes/uploadAssignment', async ({ projectId, nextPage, ...payload }) => {
  await hermesApi.updateProject(projectId, payload);
  return { nextPage };
});

export const toggleRoomNotification = createAsyncThunk<{ roomId: number }, { roomId: number }>(
  'hermes/toggleRoomNotification',
  async ({ roomId }) => {
    await hermesApi.toggleRoomNotification(roomId);
    return { roomId };
  },
);

export const patchAssignmentSolution = createAsyncThunk<
  AssignmentSolutionPayload,
  {
    assignmentId: number;
    solution: Partial<AssignmentSolution>;
  }
>('hermes/patchAssignmentSolution', async ({ assignmentId, solution }) => {
  const result = await hermesApi.updateAssignmentSolution(assignmentId, solution);
  return result.data;
});

export interface MessagesForRoom {
  roomId: number;
  messages: MessageSerializer[];
}

export interface MeetingsForProject {
  meetings: Meeting[];
  workspaceId: number;
}

export interface AssignmentSolutionPayload extends AssignmentSolution {
  assignmentId: number;
}

const sortMessagesInPlace = (messages: Message[]): Message[] => {
  return messages.sort((m1, m2) => {
    return new Date(m2.createdAt).getTime() - new Date(m1.createdAt).getTime();
  });
};

const updateSentByForMessage = (message: MessageSerializer, participants: User[]): Message => {
  const sentBy = participants.find(({ id }) => {
    return id === message.sentBy;
  });
  const newMessage: Message = {
    ...message,
    sentBy,
  };
  return newMessage;
};

const updateAssignments = (state: HermesState, sessions: SessionSerializer[]): void => {
  sessions.forEach((session) => {
    const assignments = session.assignments || [];
    assignments.forEach((assignment) => {
      state.assignments.byId[assignment.id] = assignment;
      state.assignments.allIds = [...new Set([...state.assignments.allIds, assignment.id])];
    });
  });
};

const updateMeetingsForWorkspace = (
  state: HermesState,
  meetings: Nullable<Meeting[]>,
  workspaceId: number,
): void => {
  const workspace = state.workspaces.byId[workspaceId];
  if (!workspace) {
    return;
  }

  if (!meetings) {
    return;
  }

  const ids = meetings.map(({ id }) => {
    return id;
  });
  state.meetings.allIds = [...new Set([...state.meetings.allIds, ...ids])];
  state.meetings.byId = meetings.reduce((allMeeting, meeting) => {
    meeting.workspaceId = workspaceId;
    allMeeting[meeting.id] = meeting;
    return allMeeting;
  }, state.meetings.byId);

  workspace.meetingIds = ids;
};

const updateProjectCountsBySessions = (project: Project): void => {
  project.sessionsCompleted = project.sessions
    ? project.sessions.reduce((acc, cur) => {
        return acc + (cur.completedAt ? cur.sessionValue : 0);
      }, 0)
    : 0;
  project.sessionsRemaining = (project.sessionsPaidFor - project.sessionsCompleted).toString();
  const fullSessionValue = project.sessions
    ? project.sessions?.reduce((acc, cur) => {
        return acc + cur.sessionValue;
      }, 0)
    : 0;
  project.sessionsSchedulable = project.sessionsPaidFor - fullSessionValue;
};

export const findUnsentMessage = (
  state: HermesState,
  roomId: number,
  localMessageId: string,
): Partial<Message> | undefined => {
  return state.rooms.byId[roomId]?.unsentMessages.find(({ localMessageId: id }) => {
    return id === localMessageId;
  });
};

export interface ByIdsAssignmentBlueprint {
  byId: Record<number, AssignmentBlueprint>;
  allIds: AssignmentBlueprint[];
}
export interface HermesState {
  selectedWorkspaceId: Nullable<number>;
  status: 'idle' | 'connecting' | 'connected' | 'disconnected';
  loadingMessages: boolean;
  mobileNavigationTab: HermesMobileTab;
  workspaceList: WorkspaceDisplay[];
  workspaces: ByIds<Workspace> & {
    basicIds: number[];
    projectIds: number[];
    loungeIds: number[];
  };
  workspacesLoaded: boolean;
  projects: ByIds<Project>;
  lounges: ByIds<Lounge>;
  rooms: ByIds<Room>;
  meetings: ByIds<Meeting>;
  assignments: ByIds<Omit<Assignment, 'session'>>;
  scheduler: Modal<ScheduleSession>;
  summarySubmitter: Modal<{ sessionId: number }>;
  studentFeedback: Modal<{ sessionId: number }>;
  canceler: Modal<{
    proposal: boolean;
    isLate: boolean;
    reason: { who: Nullable<number>; why: Nullable<string>; whyOther: Nullable<string> };
  }>;
  assignmentBlueprints: ByIdsAssignmentBlueprint;
  assignment: Modal<{
    page: string;
    selectedSession: Nullable<number>;
    selectedAssignmentAction: Record<string, unknown>;
    milestoneSpecials: Record<string, unknown>;
    milestoneSuperSpecials: Record<string, unknown>;
  }>;
  noShowReportedModal: Modal<unknown>;
  selectedAssignmentSolution: AssignmentSolution;
  studentFeedbackRequest: Modal<{
    open: boolean;
    sending?: boolean | undefined;
    success?: boolean | undefined;
    error?: Nullable<unknown>;
  }>;
  todoItems: { data: TodoItem[]; animationRunning: boolean };
}

const initialState: HermesState = {
  status: 'idle',
  loadingMessages: false,
  selectedWorkspaceId: null,
  mobileNavigationTab: defaultMobileNavigationTab,
  workspaceList: [],
  workspaces: {
    byId: {},
    allIds: [],
    basicIds: [],
    projectIds: [],
    loungeIds: [],
  },
  workspacesLoaded: false,
  projects: {
    byId: {},
    allIds: [],
  },
  lounges: {
    byId: {},
    allIds: [],
  },
  rooms: {
    byId: {},
    allIds: [],
  },
  meetings: {
    byId: {},
    allIds: [],
  },
  assignments: {
    byId: {},
    allIds: [],
  },
  scheduler: {
    sessionId: null,
    meetingId: null,
    number: null,
    open: false,
    proposing: false,
    responding: false,
    sending: false,
    success: false,
    error: null,
    isSelectedTimeValid: true,
  },
  summarySubmitter: {
    sessionId: null,
    open: false,
    sending: false,
    success: false,
    error: null,
  },
  studentFeedback: {
    sessionId: null,
    open: false,
    sending: false,
    success: false,
  },
  canceler: {
    open: false,
    success: false,
    proposal: false,
    isLate: false,
    reason: {
      who: null,
      why: null,
      whyOther: '',
    },
  },
  assignmentBlueprints: {
    byId: {},
    allIds: [],
  },
  assignment: {
    open: false,
    error: false,
    page: 'submission',
    selectedSession: null,
    selectedAssignmentAction: {},
    milestoneSpecials: {},
    milestoneSuperSpecials: {},
  },
  selectedAssignmentSolution: {},
  noShowReportedModal: {
    open: false,
  },
  studentFeedbackRequest: {
    open: false,
    sending: false,
    success: false,
    error: null,
  },
  todoItems: {
    animationRunning: false,
    data: [],
  },
};

interface MessageSendingPayload {
  payload: {
    roomId: number;
    localMessageId: string;
  };
}

interface MessageSentSuccessPayload {
  payload: {
    roomId: number;
    localMessageId: string;
    message: MessageSerializer;
    workspaceId?: number;
    sharedResources?: SharedResource[];
  };
}

interface UpdateMessageSilentReportPayload {
  payload: {
    roomId: number;
    messageId: number;
    reportId: number;
  };
}

interface MessageSeenPayload {
  payload: {
    roomId: number;
    userId: number;
    currentUserId: number | null;
    workspaceId: number;
  };
}

interface OpenSchedulerPayload {
  payload: {
    sessionId?: number;
    meetingId?: number;
    proposing?: boolean;
    responding?: boolean;
  };
}

interface SchedulerMeetingTimeValidityPayload {
  payload: {
    isValid: boolean;
  };
}

interface UnsentMessagePayload {
  payload: {
    message: string;
    roomId: number;
    localMessageId: string;
    sentBy: { id: number };
    attachmentIds: number[];
  };
}

interface OpenAssignmentPayload {
  payload: { sessionNumber: number; selectedAssignmentAction: Record<string, unknown> | null };
}

const handleRoomUpdate = (workspace: WorkspaceSerializer, rooms: ByIds<Room>): ByIds<Room> => {
  const existingRoomInState = rooms.byId[workspace.room.id];

  const room = {
    ...workspace.room,
    workspaceId: workspace.id,
    unsentMessages: existingRoomInState?.unsentMessages ?? [],
  } as Room;
  if (workspace.room && workspace.room.messages && workspace.room.participants) {
    room.messages = workspace.room.messages.map((message) => {
      return updateSentByForMessage(message, workspace.room.participants);
    });
  }
  return {
    byId: { ...rooms.byId, [workspace.room.id]: room },
    allIds: [...rooms.allIds, workspace.room.id],
  };
};

const handleLoungeUpdate = (
  workspace: WorkspaceSerializer,
  lounges: ByIds<Lounge>,
): ByIds<Lounge> => {
  if (workspace.lounge) {
    workspace.lounge.workspaceId = workspace.id;
    const updatedLounge = lounges.byId[workspace.lounge.id]
      ? { ...lounges.byId[workspace.lounge.id], ...workspace.lounge }
      : workspace.lounge;
    return {
      byId: {
        ...lounges.byId,
        [workspace.lounge.id]: updatedLounge,
      },
      allIds: [...lounges.allIds, workspace.lounge.id],
    };
  }
  return lounges;
};

const handleProjectUpdate = (
  workspace: WorkspaceSerializer,
  projects: ByIds<Project>,
): ByIds<Project> => {
  if (workspace.project) {
    workspace.project.workspaceId = workspace.id;
    const updatedProject = projects.byId[workspace.project.id]
      ? { ...projects.byId[workspace.project.id], ...workspace.project }
      : workspace.project;
    return {
      byId: {
        ...projects.byId,
        [workspace.project.id]: updatedProject,
      },
      allIds: [...projects.allIds, workspace.project.id],
    };
  }
  return projects;
};

const handleWorkspaceUpdate = (
  workspace: WorkspaceSerializer,
  workspaces: HermesState['workspaces'],
): HermesState['workspaces'] => {
  const parsedWorkspace: Workspace = {
    id: workspace.id,
    disabled: workspace.disabled,
    name: workspace.name,
    operationUser: workspace.operationUser,
    permissions: workspace.permissions,
    zoomDisabled: workspace.zoomDisabled,
    meetingIds: [],
    roomId: workspace.room?.id,
    projectId: workspace?.project?.id,
    loungeId: workspace?.lounge?.id,
    roomName: '',
  };

  const updatedWorkspace = workspaces.byId[parsedWorkspace.id]
    ? { ...workspaces.byId[parsedWorkspace.id], ...parsedWorkspace }
    : parsedWorkspace;
  return {
    byId: { ...workspaces.byId, [parsedWorkspace.id]: updatedWorkspace },
    allIds: [...workspaces.allIds, parsedWorkspace.id],
    projectIds: parsedWorkspace.projectId
      ? [...workspaces.projectIds, parsedWorkspace.id]
      : workspaces.projectIds,
    loungeIds: parsedWorkspace.loungeId
      ? [...workspaces.loungeIds, parsedWorkspace.id]
      : workspaces.loungeIds,
    basicIds: !(parsedWorkspace.projectId || parsedWorkspace.loungeId)
      ? [...workspaces.basicIds, parsedWorkspace.id]
      : workspaces.basicIds,
  };
};

const uniqueAllIds = (state: HermesState): void => {
  state.workspaces.allIds = [...new Set(state.workspaces.allIds)];
  state.workspaces.projectIds = [...new Set(state.workspaces.projectIds)];
  state.workspaces.loungeIds = [...new Set(state.workspaces.loungeIds)];
  state.workspaces.basicIds = [...new Set(state.workspaces.basicIds)];
  state.projects.allIds = [...new Set(state.projects.allIds)];
  state.rooms.allIds = [...new Set(state.rooms.allIds)];
};

const hermesSlice = createSlice({
  name: 'hermes',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getWorkspaceDisplayList.pending, (state) => {
      state.workspacesLoaded = false;
    });
    builder.addCase(getWorkspaceDisplayList.fulfilled, (state, { payload }) => {
      state.workspacesLoaded = true;
      state.workspaceList = payload
        ? payload.sort((workspaceA, workspaceB) => {
            return -dayjs(workspaceA.lastMessageAt).diff(dayjs(workspaceB.lastMessageAt));
          })
        : [];
    });
    builder.addCase(updateTodoItem.fulfilled, (state, { payload }) => {
      state.todoItems.data = state.todoItems.data.map((item) => {
        if (item.id === payload.id) {
          return payload;
        }
        return item;
      });
      state.todoItems.animationRunning = true;
    });
    builder.addCase(markCompletedTodoItemsAsSeen.fulfilled, (state, { payload: todoItemIds }) => {
      for (const todoItem of state.todoItems.data) {
        if (todoItemIds.includes(todoItem.id)) {
          todoItem.completionSeenAt = new Date().toISOString();
        }
      }
      state.todoItems.animationRunning = true;
    });
    builder.addCase(getWorkspaceById.fulfilled, (state, { payload: workspace }) => {
      if (workspace && !workspace.error) {
        state.rooms = handleRoomUpdate(workspace, state.rooms);
        state.projects = handleProjectUpdate(workspace, state.projects);
        state.lounges = handleLoungeUpdate(workspace, state.lounges);
        state.workspaces = handleWorkspaceUpdate(workspace, state.workspaces);
        uniqueAllIds(state);
      }
    });

    builder.addCase(getMessagesForRoom.pending, (state) => {
      state.loadingMessages = false;
    });

    builder.addCase(getMessagesForRoom.fulfilled, (state, { payload: { roomId, messages } }) => {
      const room = state.rooms.byId[roomId];

      if (!room) {
        return;
      }

      messages.forEach((message) => {
        if (
          !room.messages.find(({ id }) => {
            return id === message.id;
          })
        ) {
          const updatedMessage = updateSentByForMessage(message, room.participants);
          room.messages.push(updatedMessage);
        }
      });
      sortMessagesInPlace(room.messages);
      state.loadingMessages = false;
    });

    builder.addCase(getMessagesForRoom.rejected, (state) => {
      state.loadingMessages = false;
    });

    builder.addCase(
      getSessionsForProject.fulfilled,
      (state, { payload: { sessions, projectId } }) => {
        if (!sessions || !projectId || !Array.isArray(sessions)) {
          return;
        }
        const project = state.projects.byId[projectId];

        if (!project) {
          return;
        }

        updateMeetingsForWorkspace(
          state,
          sessions.map((session) => {
            return session.meeting;
          }),
          project.workspaceId,
        );
        project.sessions = sessions.map(({ meeting, ...session }, idx) => {
          return {
            ...session,
            number: idx + 1,
            meetingId: meeting.id,
            scheduledAt: meeting.scheduledAt,
            assignments: session.assignments?.map((assignment) => assignment.id) || [],
          };
        });
        updateAssignments(state, sessions);
        updateProjectCountsBySessions(project);
      },
    );

    builder.addCase(getProject.fulfilled, (state, { payload }) => {
      state.projects.byId[payload.id] = {
        ...state.projects.byId[payload.id],
        ...payload,
      };
    });

    builder.addCase(saveSessionForProject.pending, (state) => {
      state.scheduler.sending = true;
    });

    builder.addCase(saveSessionForProject.fulfilled, (state, { payload }) => {
      if (isErrorInPayload(payload)) {
        state.scheduler.error = payload.error?.body;
        state.scheduler.sending = false;
        return;
      }

      state.scheduler = {
        ...state.scheduler,
        open: true,
        success: true,
        sending: false,
      };
      state.canceler.reason = initialState.canceler.reason;
    });
    builder.addCase(saveMeeting.pending, (state) => {
      state.scheduler.sending = true;
    });
    builder.addCase(cancelMeeting.fulfilled, (state, { payload }) => {
      state.canceler = {
        open: true,
        proposal: false,
        success: !payload.error,
        reason: initialState.canceler.reason,
        isLate: false,
      };
    });
    builder.addCase(cancelSession.fulfilled, (state, { payload }) => {
      if (isErrorInPayload(payload)) {
        state.canceler.success = false;
      } else {
        state.canceler.success = true;
        state.canceler.isLate = payload.countAs === 'late_cancelled';
        state.canceler.proposal = payload.proposing;
      }
      state.canceler.open = true;
      state.canceler.reason = initialState.canceler.reason;
    });
    builder.addCase(saveMeeting.fulfilled, (state, { payload }) => {
      if (isErrorInPayload(payload)) {
        state.scheduler.error = payload.error?.body;
        state.scheduler.sending = false;
        return;
      }
      state.scheduler = {
        ...state.scheduler,
        open: true,
        success: true,
      };
    });
    builder.addCase(submitSessionSummary.fulfilled, (state, { payload }) => {
      if (isErrorInPayload(payload)) {
        state.scheduler.error = payload.error?.body;
        state.scheduler.sending = false;
        return;
      }
      state.summarySubmitter.open = true;
      state.summarySubmitter.success = true;
    });
    builder.addCase(
      getMeetingsForWorkspace.fulfilled,
      (state, { payload: { meetings, workspaceId } }) => {
        updateMeetingsForWorkspace(state, meetings, workspaceId);
      },
    );
    builder.addCase(getTodoItems.fulfilled, (state, { payload }) => {
      state.todoItems.data = payload;
    });
    builder.addCase(submitStudentFeedback.pending, (state) => {
      state.studentFeedback.sending = true;
    });
    builder.addCase(submitStudentFeedback.fulfilled, (state) => {
      state.studentFeedback.open = true;
      state.studentFeedback.success = true;
    });
    builder.addCase(submitStudentFeedback.rejected, (state) => {
      state.studentFeedback.open = false;
      state.studentFeedback.sending = false;
    });
    builder.addCase(getAssignmentBlueprints.fulfilled, (state, { payload }) => {
      // We use `abortableAuthFetch` to fetch assignment blueprints that incorrectly handles (absorbs) all errors.
      // When a user sends the request with an invalid or expired token the backend responds with 401 status code,
      // but will still call this function because the `abortableAuthFetch` returns an OK value in all cases.
      // https://polygence.atlassian.net/browse/BSH-2035
      if (Array.isArray(payload)) {
        state.assignmentBlueprints.allIds = payload;
        payload.forEach((assignment) => {
          state.assignmentBlueprints.byId[assignment.id] = assignment;
        });
      }
    });
    builder.addCase(uploadAssignment.fulfilled, (state, { payload: { nextPage = 'success' } }) => {
      state.assignment.page = nextPage;
    });
    builder.addCase(toggleRoomNotification.fulfilled, (state, { payload: { roomId } }) => {
      const room = state.rooms.byId[roomId];
      if (room) {
        room.notificationEnabled = !room.notificationEnabled;
      }
    });
    builder.addCase(
      patchAssignmentSolution.fulfilled,
      (state, { payload: { assignmentId, ...solution } }) => {
        const assignment = state.assignments.byId[assignmentId];

        if (!assignment) {
          return;
        }

        assignment.assignmentSolution = solution;
      },
    );
    builder.addCase(submitStudentFeedbackRequest.pending, (state) => {
      state.studentFeedbackRequest.sending = true;
    });
    builder.addCase(submitStudentFeedbackRequest.fulfilled, (state) => {
      state.studentFeedbackRequest.open = true;
      state.studentFeedbackRequest.success = true;
    });
  },
  reducers: {
    connecting: (state) => {
      state.status = 'connecting';
    },
    connected: (state) => {
      state.status = 'connected';
    },
    connectionDied: (state) => {
      state.status = 'disconnected';
      Object.values(state.rooms.byId).forEach((room) => {
        let sentBy = 0;
        room.unsentMessages.forEach((message) => {
          message.status = 'failed';
          sentBy = message.sentBy?.id ?? 0;
        });
        unsentMessagesLocalStorageHandler.set(room.id, sentBy, room.unsentMessages);
      });
    },
    closed: (state) => {
      state.status = 'idle';
    },
    messageSent: (
      state,
      { payload: { message, roomId, localMessageId, sentBy, attachmentIds } }: UnsentMessagePayload,
    ) => {
      const room = state.rooms.byId[roomId];

      if (!room) {
        return;
      }

      const sentByUser = room.participants.find(({ id }) => {
        return id === sentBy.id;
      });

      const now = new Date().toISOString();

      room.unsentMessages.unshift({
        attachmentIds,
        content: message,
        createdAt: now,
        emailNotificationSentAt: null,
        executedAfterCreate: false,
        extra: {},
        id: Infinity,
        localMessageId,
        messageType: MessageType.MARKDOWN,
        room: room.id,
        seenBy: [sentBy.id],
        sentBy: sentByUser,
        silentReport: null,
        status: 'sending',
        updatedAt: now,
      });

      unsentMessagesLocalStorageHandler.set(room.id, sentBy.id, room.unsentMessages);
    },
    messageSending: (state, { payload: { roomId, localMessageId } }: MessageSendingPayload) => {
      const message: Partial<Message> | undefined = findUnsentMessage(
        state,
        roomId,
        localMessageId,
      );
      if (message) {
        message.status = 'sending';
      }
    },
    messageSentSuccess: (
      state,
      { payload: { roomId, localMessageId, message, workspaceId } }: MessageSentSuccessPayload,
    ) => {
      const workspace = state.workspaceList.find((workspace) => workspace.id === workspaceId);
      const shouldCountAsUnseen =
        (state.mobileNavigationTab !== HermesMobileTab.Chat &&
          state.mobileNavigationTab !== HermesMobileTab.FullScreen) ||
        (state.selectedWorkspaceId !== null && state.selectedWorkspaceId !== workspaceId);

      if (workspace) {
        if (shouldCountAsUnseen) {
          workspace.unseenMessageCount++;
        }

        workspace.lastMessageAt = message.createdAt;
      }

      const room = state.rooms.byId[roomId];

      if (!room) {
        return;
      }

      const dirtyMessage = room.unsentMessages.find(({ localMessageId: id }) => {
        return id === localMessageId;
      });

      const dirtyMessageIndex = dirtyMessage ? room.unsentMessages.indexOf(dirtyMessage) : -1;
      if (dirtyMessage && dirtyMessageIndex !== -1) {
        room.unsentMessages.splice(dirtyMessageIndex, 1);
        unsentMessagesLocalStorageHandler.set(room.id, message.sentBy ?? 0, room.unsentMessages);
      } else if (shouldCountAsUnseen) {
        room.unseenMessagesCount++;
      }
      const updatedMessage = updateSentByForMessage(message, room.participants);
      const messageAlreadyReceived = Boolean(
        room.messages.find((message) => {
          return message.localMessageId === localMessageId;
        }),
      );
      if (messageAlreadyReceived) {
        return;
      }
      room.messages.push(updatedMessage);
      sortMessagesInPlace(room.messages);
    },
    messageSentFailure: (state, { payload: { roomId, localMessageId } }: MessageSendingPayload) => {
      const message: Partial<Message> | undefined = findUnsentMessage(
        state,
        roomId,
        localMessageId,
      );
      if (message) {
        message.status = 'failed';
      }
    },
    updateMessageSilentReport: (
      state,
      { payload: { roomId, messageId, reportId } }: UpdateMessageSilentReportPayload,
    ) => {
      const room = state.rooms.byId[roomId];
      if (!room) {
        return;
      }

      const message = room.messages.find(({ id }) => {
        return id === messageId;
      });

      if (message) {
        message.silentReport = reportId;
      }
    },
    messagesSeen: (
      state,
      { payload: { roomId, userId, currentUserId, workspaceId } }: MessageSeenPayload,
    ) => {
      if (userId === currentUserId) {
        const room = state.rooms.byId[roomId];

        if (room) {
          room.unseenMessagesCount = 0;
        }

        const workspace = state.workspaceList.find((workspace) => workspace.id === workspaceId);
        if (workspace) {
          workspace.unseenMessageCount = 0;
        }
      }
    },
    updateAIMessageThread: (
      state,
      {
        payload,
      }: { payload: { roomId: RoomId; aiMessageThreadId: HermesAIMessageThreadId; title: string } },
    ) => {
      const message = state.rooms.byId[payload.roomId]?.messages.find(
        (message) => message.aiMessageThread === payload.aiMessageThreadId,
      );
      if (message) {
        message.aiMessageThreadTitle = payload.title;
      }
    },
    todoItemCompleted: (state, { payload: { id } }: { payload: { id: TodoItemId } }) => {
      const todoItem = state.todoItems.data.find((item) => item.id === id);
      if (todoItem) {
        todoItem.status = 'completed';
        state.todoItems.animationRunning = true;
      }
    },
    stopTodoAnimation: (state) => {
      state.todoItems.animationRunning = false;
    },
    workspaceSelected: (state, { payload: workspaceId }: { payload: Nullable<number> }) => {
      state.selectedWorkspaceId = workspaceId;
    },
    openScheduler: (
      state,
      {
        payload: { sessionId, meetingId, proposing = false, responding = false } = {},
      }: OpenSchedulerPayload,
    ) => {
      state.scheduler.sessionId = sessionId;
      state.scheduler.meetingId = meetingId;
      state.scheduler.open = true;
      state.scheduler.proposing = proposing;
      state.scheduler.responding = responding;
    },
    closeScheduler: (state) => {
      state.scheduler = initialState.scheduler;
    },
    resetScheduler: (state) => {
      state.scheduler.sending = false;
    },
    setSchedulerMeetingTimeValidity: (
      state,
      { payload: { isValid } }: SchedulerMeetingTimeValidityPayload,
    ) => {
      state.scheduler.isSelectedTimeValid = isValid;
    },
    closeCanceler: (state) => {
      state.canceler = initialState.canceler;
    },
    updateCancellationReason: (
      state: HermesState,
      {
        payload: { name, value },
      }: {
        payload: {
          name: keyof HermesState['canceler']['reason'];
          value: HermesState['canceler']['reason'][keyof HermesState['canceler']['reason']];
        };
      },
    ) => {
      if (state.canceler.reason) {
        state.canceler.reason[name] = value;
      }
    },
    openSummarySubmitter: (
      state,
      { payload: { sessionId } }: { payload: { sessionId: number } },
    ) => {
      state.summarySubmitter.sessionId = sessionId;
      state.summarySubmitter.open = true;
    },
    closeSummarySubmitter: (state) => {
      state.summarySubmitter = initialState.summarySubmitter;
    },
    openSessionFeedback: (
      state,
      { payload: { sessionId } }: { payload: { sessionId: number } },
    ) => {
      state.studentFeedback.sessionId = sessionId;
      state.studentFeedback.open = true;
    },
    closeSessionFeedback: (state) => {
      state.studentFeedback = initialState.studentFeedback;
    },
    openFeedbackRequest: (state) => {
      state.studentFeedbackRequest.open = true;
    },
    closeFeedbackRequest: (state) => {
      state.studentFeedbackRequest = initialState.studentFeedbackRequest;
    },
    openAssignmentModal: (
      state,
      { payload: { sessionNumber, selectedAssignmentAction } }: OpenAssignmentPayload,
    ) => {
      state.assignment.open = true;
      state.assignment.selectedSession = sessionNumber;
      state.assignment.selectedAssignmentAction = selectedAssignmentAction;
    },
    closeAssignmentModal: (state) => {
      state.assignment = initialState.assignment;
    },
    setAssignmentModalToSpecialPage: (state) => {
      state.assignment.page = 'special';
      state.assignment.error = false;
    },
    setAssignmentModalToSuperSpecialPage: (state) => {
      state.assignment.page = 'superSpecial';
      state.assignment.error = false;
    },
    setAssignmentModalToAbstractMilestoneSpecialPage: (state) => {
      state.assignment.page = 'abstractMilestoneSpecial';
      state.assignment.error = false;
    },
    setAssignmentModalToSubmissionPage: (state) => {
      state.assignment.page = 'submission';
    },
    setAssignmentModalToSuccessPage: (state) => {
      state.assignment.page = 'success';
      state.assignment.error = false;
    },
    assignmentUploadFailure: (state) => {
      state.assignment.error = true;
    },
    sessionSummarySaving: (state) => {
      state.summarySubmitter.sending = true;
    },
    sessionSummaryFinishSaving: (state) => {
      state.summarySubmitter.sending = false;
    },
    updateMilestoneSpecials: (state, { payload }: { payload: Record<string, unknown> }) => {
      state.assignment.milestoneSpecials = {
        ...state.assignment.milestoneSpecials,
        ...payload,
      };
    },
    updateMilestoneSuperSpecials: (state, { payload }: { payload: Record<string, unknown> }) => {
      state.assignment.milestoneSuperSpecials = {
        ...state.assignment.milestoneSuperSpecials,
        ...payload,
      };
    },
    openNoShowReportedModal: (state) => {
      state.noShowReportedModal.open = true;
    },
    closeNoShowReportedModal: (state) => {
      state.noShowReportedModal.open = false;
    },
    setMobileNavigationTab: (
      state,
      { payload: mobileNavigationTab = defaultMobileNavigationTab }: { payload: HermesMobileTab },
    ) => {
      state.mobileNavigationTab = mobileNavigationTab;
    },
    updateAssignmentSolution: (
      state: HermesState,
      {
        payload: { assignmentId, solution },
      }: { payload: { assignmentId: number; solution: Partial<AssignmentSolution> } },
    ) => {
      const assignment = state.assignments.byId[assignmentId];

      if (assignment) {
        assignment.assignmentSolution = {
          ...assignment.assignmentSolution,
          ...solution,
        };
      }
    },
  },
});

const { actions, reducer } = hermesSlice;

export const hermesActions = actions;
export const hermesReducer = reducer;
