import {
  ApplicationComponentDescription,
  ComponentSection,
  schoolGraduationYearProps,
} from 'src/students/student-application';
import {
  contentSpacer,
  narrowFieldSpacer,
  textFieldSpacer,
} from 'src/students/student-application/common-section-components';
import { referralQuestions } from 'src/students/student-application/referral-questions';

const RequiredPodInformationIds = {
  PARENT_INFO_SECTION_ID: 'parent-info',
  REFERRAL_SECTION_ID: 'referral',
  SCHOOL_SECTION_ID: 'school',
} as const;

type RequiredPodInformationId =
  (typeof RequiredPodInformationIds)[keyof typeof RequiredPodInformationIds];

type RequiredPodInformationSection = ComponentSection<RequiredPodInformationId>;

const sectionBackground = 'background-white';

const podsReferralQuestions = Object.freeze<ApplicationComponentDescription[]>([
  {
    component: 'ComponentsRadioButton',
    tag: 'profile',
    props: {
      name: 'podsReferral',
      label: 'Email',
      value: 'Email',
    },
    validation: {
      required: true,
    },
  },
  {
    component: 'ComponentsRadioButton',
    tag: 'profile',
    props: {
      name: 'podsReferral',
      label: 'While browsing the Polygence website',
      value: 'While browsing the Polygence website',
    },
    validation: {
      required: true,
    },
  },
  {
    component: 'ComponentsRadioButton',
    tag: 'profile',
    props: {
      name: 'podsReferral',
      label: 'My counselor',
      value: 'counselor',
    },
    validation: {
      required: true,
    },
  },
  {
    component: 'ComponentsRadioButton',
    tag: 'profile',
    props: {
      name: 'podsReferral',
      label: 'My parent',
      value: 'parent',
    },
  },
  {
    component: 'Box',
    display: {
      $is: {
        field: 'podsReferral',
        value: 'parent',
      },
    },
    props: {
      children: [
        {
          component: 'InputField',
          tag: 'profile',
          props: {
            label: 'Where did your parent hear about Pods?',
            floating: false,
            placeholder: 'e.g. Google or friend',
            name: 'podsReferralExtra',
          },
          validation: {
            required: false,
          },
        },
        textFieldSpacer,
      ],
    },
  },
  {
    component: 'ComponentsRadioButton',
    tag: 'profile',
    props: {
      name: 'podsReferral',
      label: 'Word of mouth / My friend or family member did Pods',
      value: 'word_of_mouth_friend',
    },
  },
  {
    component: 'Box',
    display: {
      $is: {
        field: 'podsReferral',
        value: 'word_of_mouth_friend',
      },
    },
    props: {
      children: [
        {
          component: 'InputField',
          tag: 'profile',
          props: {
            label: 'Who introduced you to Pods?',
            name: 'podsReferralExtra',
          },
          validation: {
            required: true,
          },
        },
        textFieldSpacer,
      ],
    },
  },
  {
    component: 'ComponentsRadioButton',
    tag: 'profile',
    props: {
      name: 'podsReferral',
      label: 'Google search',
      value: 'google_search',
    },
  },
  {
    component: 'Box',
    display: {
      $is: {
        field: 'podsReferral',
        value: 'google_search',
      },
    },
    props: {
      children: [
        {
          component: 'InputField',
          tag: 'profile',
          props: {
            label: 'What search term did you use?',
            name: 'podsReferralExtra',
          },
          validation: {
            required: true,
          },
        },
        textFieldSpacer,
      ],
    },
  },
  {
    component: 'ComponentsRadioButton',
    tag: 'profile',
    props: {
      name: 'podsReferral',
      label: 'Webinar',
      value: 'webinar',
    },
  },
  {
    component: 'Box',
    display: {
      $is: {
        field: 'podsReferral',
        value: 'webinar',
      },
    },
    props: {
      children: [
        {
          component: 'InputField',
          tag: 'profile',
          props: {
            label: 'What webinar was it?',
            name: 'podsReferralExtra',
          },
          validation: {
            required: true,
          },
        },
        textFieldSpacer,
      ],
    },
  },
  {
    component: 'ComponentsRadioButton',
    tag: 'profile',
    props: {
      name: 'podsReferral',
      label: 'Polygence Mentor',
      value: 'mentor',
    },
  },
  {
    component: 'Box',
    display: {
      $is: {
        field: 'podsReferral',
        value: 'mentor',
      },
    },
    props: {
      children: [
        {
          component: 'InputField',
          tag: 'profile',
          props: {
            label: 'What is the name of the mentor who referred you?',
            floating: false,
            name: 'podsReferralExtra',
          },
          validation: {
            required: true,
          },
        },
        textFieldSpacer,
      ],
    },
  },
  {
    component: 'ComponentsRadioButton',
    tag: 'profile',
    props: {
      name: 'podsReferral',
      label: 'TikTok',
      value: 'tiktok',
    },
  },
  {
    component: 'Box',
    display: {
      $is: {
        field: 'podsReferral',
        value: 'tiktok',
      },
    },
    props: {
      children: [
        {
          component: 'InputField',
          tag: 'profile',
          props: {
            label: 'Please specify the Tiktok channel',
            name: 'podsReferralExtra',
          },
          validation: {
            required: true,
          },
        },
        textFieldSpacer,
      ],
    },
  },
  {
    component: 'ComponentsRadioButton',
    tag: 'profile',
    props: {
      name: 'podsReferral',
      label: 'Youtube',
      value: 'youtube',
    },
    validation: {
      required: true,
    },
  },
  {
    component: 'ComponentsRadioButton',
    tag: 'profile',
    props: {
      name: 'podsReferral',
      label: 'Social media influencer',
      value: 'influencer',
    },
  },
  {
    component: 'Box',
    display: {
      $is: {
        field: 'podsReferral',
        value: 'influencer',
      },
    },
    props: {
      children: [
        {
          component: 'InputField',
          tag: 'profile',
          props: {
            label: 'Please specify influencer name',
            name: 'podsReferralExtra',
          },
          validation: {
            required: true,
          },
        },
        textFieldSpacer,
      ],
    },
  },
  {
    component: 'ComponentsRadioButton',
    tag: 'profile',
    props: {
      name: 'podsReferral',
      label: 'Podcast',
      value: 'podcast',
    },
  },
  {
    component: 'Box',
    display: {
      $is: {
        field: 'podsReferral',
        value: 'podcast',
      },
    },
    props: {
      children: [
        {
          component: 'InputField',
          tag: 'application',
          props: {
            label: 'Which one?',
            name: 'podsReferralExtra',
          },
          validation: {
            required: true,
          },
        },
        textFieldSpacer,
      ],
    },
  },
]);

const requiredPodInformationSections = Object.freeze<RequiredPodInformationSection[]>([
  {
    id: RequiredPodInformationIds.PARENT_INFO_SECTION_ID,
    elements: [
      {
        component: 'Box',
        background: sectionBackground,
        props: {
          children: [
            {
              component: 'ComponentsText',
              props: {
                children: "Please provide your parent or guardian's email address",
                size: 'medium',
                fontWeight: 'bold',
              },
            },
            narrowFieldSpacer,
            {
              component: 'ComponentsText',
              props: {
                children: "We'll use this email address to coordinate billing and payment.",
                size: 'small',
                className: 'text-muted',
              },
            },
            {
              component: 'EmailInputField',
              props: { placeholder: 'Parent/guardian email address', name: 'parentEmail' },
              validation: {
                required: true,
                email: true,
                cantBeSameAs: {
                  dataField: 'ownEmail',
                  errorMsg: "You can't use your own email address.",
                },
              },
            },
          ],
        },
      },
    ],
  },
  {
    id: RequiredPodInformationIds.SCHOOL_SECTION_ID,
    elements: [
      {
        component: 'Box',
        background: sectionBackground,
        props: {
          children: [
            {
              component: 'ComponentsText',
              props: {
                children: 'What high school are you attending?',
                size: 'medium',
                fontWeight: 'bold',
              },
            },
            textFieldSpacer,
            {
              component: 'SchoolSelector',
              props: {
                name: 'highSchool',
                nameLabel: 'highSchoolLabel',
                otherName: 'highSchoolOther',
                placeholder: 'Write here',
                defaultOptions: [],
              },
              validation: {
                selectWithOther: true,
              },
            },
            {
              component: 'DatePickerWithLabel',
              tag: 'profile',
              props: {
                ...schoolGraduationYearProps,
                minDate: new Date('1950'),
                label: 'High School graduation year',
                customInput: true,
              },
              validation: {
                required: true,
              },
            },
          ],
        },
      },
    ],
  },
  {
    id: RequiredPodInformationIds.REFERRAL_SECTION_ID,
    elements: [
      {
        component: 'Box',
        display: {
          $is: {
            field: 'isUpsellStudent',
            value: true,
          },
        },
        background: sectionBackground,
        props: {
          children: [
            {
              component: 'ComponentsText',

              props: {
                children: 'How did you hear about Pods?',
                size: 'medium',
                fontWeight: 'bold',
              },
            },
            contentSpacer,
            ...podsReferralQuestions,
          ],
        },
      },
      {
        component: 'Box',
        display: {
          $is: {
            field: 'isUpsellStudent',
            value: false,
          },
        },
        background: sectionBackground,
        props: {
          children: [
            {
              component: 'ComponentsText',

              props: {
                children: 'How did you hear about Polygence?',
                size: 'medium',
                fontWeight: 'bold',
              },
            },
            contentSpacer,
            ...referralQuestions,
          ],
        },
      },
    ],
  },
]);

export const REQUIRED_POD_INFORMATION_SECTIONS = requiredPodInformationSections;
