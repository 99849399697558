import { type OptionType, Select, type SingleValue } from '@polygence/components';
import classNames from 'classnames';
import { ComponentProps } from 'react';

import InputWrapper from 'src/components/mentor/InputWrapper';

type SelectProps = ComponentProps<typeof Select>;

interface SelectInputProps {
  label: string;
  name: SelectProps['name'];
  className?: string;
  id: SelectProps['id'];
  small_font: string;
  options: SelectProps['options'];
  isSearchable: SelectProps['isSearchable'];
  onChange: Exclude<SelectProps['onChange'], undefined>;
  defaultValue: SingleValue<OptionType>;
  displayError: boolean;
  required: boolean;
  value: SingleValue<OptionType>;
  patchDefault: boolean;
  isClearable?: SelectProps['isClearable'];
  disabled?: SelectProps['isDisabled'];
}

export const SelectInput = ({
  label,
  name,
  className = '',
  id,
  small_font: smallFont,
  options,
  isSearchable,
  onChange,
  defaultValue,
  displayError,
  required,
  value: selectedOption,
  patchDefault,
  isClearable = false,
  disabled = false,
}: SelectInputProps) => {
  if (patchDefault && defaultValue) {
    onChange(
      { value: defaultValue.value, label: defaultValue.label },
      {
        action: 'select-option',
        name,
        option: undefined,
      },
    );
  }

  return (
    <InputWrapper required={required} value={selectedOption?.value ?? ''}>
      <span className={classNames({ required })}>{label}</span>
      <div className="small text-muted">{smallFont}</div>
      <Select
        className={`mb-5 ${className}`}
        options={options}
        isSearchable={isSearchable}
        isClearable={isClearable}
        name={name}
        id={id}
        onChange={onChange}
        value={selectedOption}
        isDisabled={disabled}
      />
      {displayError && <div className="text-danger small">This field is required.</div>}
    </InputWrapper>
  );
};
