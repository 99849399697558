import { Modal, ModalBody } from '@polygence/components';
import { useState, useMemo } from 'react';
import type { ReactNode, ButtonHTMLAttributes } from 'react';
import ReactModal from 'react-modal';
import type { SetOptional } from 'type-fest';

export { ModalBody, ModalFooter, ModalTitle } from '@polygence/components';

const ModalCloseButton = (props: ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <button type="button" data-cy="modal-close" className="close" aria-label="Close" {...props}>
      <span aria-hidden="true">&times;</span>
    </button>
  );
};

interface OldModalProps {
  isOpen: boolean;
  close: () => void;
  shouldCloseOnOverlayClick?: boolean;
  overlayClassName?: string;
  className?: string;
  children: ReactNode;
}

export const OldModal = ({
  isOpen,
  close,
  shouldCloseOnOverlayClick,
  overlayClassName = 'ReactModal__Overlay',
  className = 'modal-overlay session-overlay session-overlay-text session-style p-5',
  children,
  ...props
}: OldModalProps) => {
  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={close}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      overlayClassName={overlayClassName}
      className={className}
      {...props}
    >
      <ModalCloseButton onClick={close} />
      {children}
    </ReactModal>
  );
};

export const useOldModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  const open = () => {
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
  };

  const ModelComponent = useMemo(() => {
    return function InnerModelComponent(props: SetOptional<OldModalProps, 'isOpen' | 'close'>) {
      const { children } = props;
      return (
        <OldModal isOpen={isOpen} close={close} {...props}>
          <ModalBody>{children}</ModalBody>
        </OldModal>
      );
    };
  }, [isOpen]);

  return [ModelComponent, open, close, isOpen];
};

interface ModalProps {
  isOpen: boolean;
  close: () => void;
  backdrop?: boolean | 'static' | undefined;
  overlayClassName?: string;
  centered?: boolean;
  className?: string;
  children: ReactNode;
  closeButton?: boolean;
}

export const useModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  const open = () => {
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
  };

  const ModelComponent = useMemo(() => {
    return function InnerModelComponent(props: SetOptional<ModalProps, 'isOpen' | 'close'>) {
      const { children } = props;
      return (
        <Modal isOpen={isOpen} onHide={close} {...props} show={isOpen}>
          <ModalBody>{children}</ModalBody>
        </Modal>
      );
    };
  }, [isOpen]);

  return [ModelComponent, open, close, isOpen] as const;
};
