import { useCommonSelector } from '@polygence/common';
import * as paymentApi from '@polygence/common/api/payment';
import type { ProductId } from '@polygence/common/types/common';
import { Text, TileSelect, Tooltip } from '@polygence/components';
import classNames from 'classnames';
import { ChangeEvent, useState } from 'react';

import { SpinningWheel } from 'src/components/SpinningWheel';
import styles from 'src/components/product/ProductSelector.module.sass';
import {
  calculatePriceForProducts,
  calculatePriceWithDiscount,
  filterProducts,
  formatPrice,
} from 'src/components/product/utils';
import { ApplicationFieldTypes } from 'src/constants/application-field-types';
import { usePartnerChannelsQuery } from 'src/reducers/adminReducers';
import { useProductsQuery } from 'src/reducers/productReducer';
import type { PartnershipArrangement } from 'src/types/zeus';

const useCurrentPartnershipArrangement = (
  originalChannel: string | undefined,
  originalPartnershipArrangement?: PartnershipArrangement,
) => {
  const channel =
    useCommonSelector((state) => {
      return state.channel?.value;
    }) || originalChannel;
  const { data: partnerChannels } = usePartnerChannelsQuery();
  if (!partnerChannels || !channel || !partnerChannels.byTag || !partnerChannels.byTag[channel]) {
    return originalPartnershipArrangement;
  }
  return partnerChannels.byTag[channel]?.partnershipArrangement;
};

interface ProductsTotalProps {
  selectedProductIds: number[];
  partnershipArrangement: PartnershipArrangement;
  channel: string;
}

export const ProductsTotal = ({
  selectedProductIds = [],
  partnershipArrangement: originalPartnershipArrangement,
  channel,
}: ProductsTotalProps) => {
  const { data: products } = useProductsQuery();
  const partnershipArrangement = useCurrentPartnershipArrangement(
    channel,
    originalPartnershipArrangement,
  );

  if (!products) {
    return null;
  }
  const selectedProducts = products.filter(({ id }) => {
    return selectedProductIds.includes(id);
  });
  const total = calculatePriceForProducts(selectedProducts, partnershipArrangement);
  return (
    <Text alignment="center" size="medium">
      Total: {formatPrice(total)}
    </Text>
  );
};

interface ProductSelectorProps {
  name: string;
  selectedProductIds: number[];
  displayProductIds?: number[];
  disabledProductIds?: number[];
  onChange?: (event: { target: { name: string; value: number[] } }, tag?: string) => void;
  channel?: string;
  condensed?: boolean;
  admin?: boolean;
  showPrice?: boolean;
  partnershipArrangement?: PartnershipArrangement;
  wrapper?: string;
  paymentIntentUuid?: string;
  displayPitchable?: boolean;
  disabled?: boolean;
}

export const ProductSelector = ({
  name,
  selectedProductIds = [],
  onChange,
  channel: originalChannel,
  condensed = false,
  admin = false,
  showPrice = false,
  partnershipArrangement: originalPartnershipArrangement,
  displayProductIds,
  disabledProductIds,
  wrapper = 'gridWrapper',
  paymentIntentUuid,
  displayPitchable = false,
  disabled,
}: ProductSelectorProps) => {
  const { data: products } = useProductsQuery();
  const [updating, setUpdating] = useState(false);
  const partnershipArrangement = useCurrentPartnershipArrangement(
    originalChannel,
    originalPartnershipArrangement,
  );

  const filteredProducts = filterProducts(products, { displayProductIds, displayPitchable });

  const handleChange =
    (id: ProductId) =>
    ({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
      if (!onChange) {
        return;
      }

      const value = checked
        ? [...selectedProductIds, id]
        : selectedProductIds.filter((item) => item !== id);

      if (paymentIntentUuid) {
        setUpdating(true);
        void paymentApi
          .updatePaymentIntentProducts(paymentIntentUuid, { selectedProducts: value })
          .then(() => onChange({ target: { name, value } }, ApplicationFieldTypes.APPLICATION))
          .finally(() => {
            setUpdating(false);
          });
      } else {
        onChange({ target: { name, value } }, ApplicationFieldTypes.APPLICATION);
      }
    };

  if (!products || (showPrice && !partnershipArrangement)) {
    return <SpinningWheel />;
  }

  const styleName = styles['condensed'] || '';
  return (
    <div className={classNames(styles[wrapper], { [styleName]: condensed })}>
      {filteredProducts?.map(
        ({
          publicName,
          publicDetails,
          name: internalName,
          id,
          basePriceInDollars,
          canDiscountBeApplied,
          deprecated,
        }) => {
          const price =
            showPrice &&
            formatPrice(
              canDiscountBeApplied
                ? calculatePriceWithDiscount(basePriceInDollars, partnershipArrangement)
                : basePriceInDollars,
            );
          const displayedName = admin ? internalName : publicName;
          const isDeprecated = admin ? deprecated : false;

          if (isDeprecated && !selectedProductIds.includes(id)) {
            return null;
          }

          const productDisplay = (
            <p className="d-flex flex-column">
              {displayedName}
              {price && <span>{price}</span>}
            </p>
          );
          return (
            <TileSelect
              key={id}
              type="checkbox"
              value={`${id}`}
              checked={selectedProductIds.includes(id)}
              disabled={
                disabled ||
                !onChange ||
                disabledProductIds?.includes(id) ||
                updating ||
                isDeprecated
              }
              onChange={handleChange(id)}
            >
              {admin || !publicDetails ? (
                productDisplay
              ) : (
                <Tooltip tip={publicDetails} placement="top">
                  {productDisplay}
                </Tooltip>
              )}
            </TileSelect>
          );
        },
      )}
    </div>
  );
};
