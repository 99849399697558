import { externalLink, ensureProtocol } from '@polygence/common';
import { Card } from '@polygence/components';

import styles from 'src/components/student/ScholarPage/ProjectLink/projectLink.module.sass';
import type { ProjectLink as ProjectLinkType } from 'src/types/marketplace';

const ProjectLink = ({ url, outcomeTypeDetails: { buttonText, tileImage } }: ProjectLinkType) => {
  const defaultTileImage = 'https://dpl6hyzg28thp.cloudfront.net/media/project-link-fallback.png';
  return (
    <Card className={styles['projectLinkArticle']} href={ensureProtocol(url)} {...externalLink}>
      <img src={tileImage ?? defaultTileImage} alt="Card's background illustration" />
      <div className={styles['actions']}>{buttonText}</div>
    </Card>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default ProjectLink;
