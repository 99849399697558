import ProjectLink from 'src/components/student/ScholarPage/ProjectLink/ProjectLink';
import styles from 'src/components/student/ScholarPage/ProjectLink/projectLink.module.sass';
import type { ProjectLink as ProjectLinkType } from 'src/types/marketplace';

export const ProjectLinkGrid = ({ projectLinks }: { projectLinks: Array<ProjectLinkType> }) => {
  return (
    <div className={styles['projectLinkGrid']}>
      {projectLinks.map((link: ProjectLinkType) => {
        const outcomeFileLink = link?.permOutcomeFile?.[0]?.url ?? '';
        return (
          <ProjectLink
            key={link.url}
            url={link.url ? link.url : outcomeFileLink}
            outcomeTypeDetails={link.outcomeTypeDetails}
            permOutcomeFile={link.permOutcomeFile}
            id={link.id}
            platform={link.platform}
          />
        );
      })}
    </div>
  );
};
