import { MentorProfileId } from '@polygence/common';
import { useGetMentorAvailabilitiesQuery } from '@polygence/common/api/mentor-availability';
import { ProjectType } from '@polygence/common/types/hermes';
import { cn, Heading, Spacer, Select } from '@polygence/components';
import { createContext, useContext, useState, KeyboardEventHandler, useMemo } from 'react';

import { Loader } from 'src/components/Loader';
import { MentorAvailability } from 'src/components/mentor/Availability/MentorAvailability';
import { getLabelForProjectType } from 'src/components/mentor/Availability/getLabelForProjectType';

interface AllMentorAvailabilitiesContextType {
  selectedMentorAvailabilityIndex: number;
  setSelectedMentorAvailabilityIndex?: (index: number) => void;
}

const AllMentorAvailabilitiesContext = createContext<AllMentorAvailabilitiesContextType>({
  selectedMentorAvailabilityIndex: 0,
});

const MentorAvailabilityTab = ({ title, index }: { title: string; index: number }) => {
  const { selectedMentorAvailabilityIndex, setSelectedMentorAvailabilityIndex } = useContext(
    AllMentorAvailabilitiesContext,
  );
  const selected = index === selectedMentorAvailabilityIndex;

  const selectTab = () => {
    setSelectedMentorAvailabilityIndex?.(index);
  };

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (e.code === 'Space' || e.code === 'Enter') {
      selectTab();
    }
  };

  return (
    <div
      className={cn(
        'tw-cursor-pointer tw-border-0 tw-border-solid tw-px-3 tw-py-2 tw-text-gray-500',
        {
          'tw-border-b-2 tw-border-primary-400 tw-text-gray-900': selected,
          'hover:tw-border-b-2 hover:tw-border-gray-400 hover:tw-text-gray-800': !selected,
        },
      )}
      role="button"
      tabIndex={0}
      onKeyDown={handleKeyDown}
      onClick={selectTab}
    >
      {title}
    </div>
  );
};

const MentorAvailabilitySelect = ({ mentorProfileId }: { mentorProfileId: MentorProfileId }) => {
  const { data: mentorAvailabilities } = useGetMentorAvailabilitiesQuery({ mentorProfileId });
  const { selectedMentorAvailabilityIndex, setSelectedMentorAvailabilityIndex } = useContext(
    AllMentorAvailabilitiesContext,
  );

  const mentorAvailabilityOptions = mentorAvailabilities?.map((mentorAvailability, index) => ({
    label: getLabelForProjectType(mentorAvailability.projectType.value),
    value: index,
  }));

  const selectedMentorAvailabilityOption =
    mentorAvailabilityOptions && mentorAvailabilityOptions[selectedMentorAvailabilityIndex];

  return (
    <Select
      size="small"
      options={mentorAvailabilityOptions}
      value={selectedMentorAvailabilityOption}
      onChange={(option) => {
        if (option) {
          setSelectedMentorAvailabilityIndex?.(option.value as number);
        }
      }}
    />
  );
};

const MentorAvailabilitySelector = ({ mentorProfileId }: { mentorProfileId: MentorProfileId }) => {
  const { data: mentorAvailabilities } = useGetMentorAvailabilitiesQuery({ mentorProfileId });

  if (!mentorAvailabilities || mentorAvailabilities?.length === 1) {
    return null;
  }

  if (mentorAvailabilities?.length <= 4) {
    return (
      <div className="tw-border-0 tw-border-b tw-border-solid tw-border-gray-400">
        <div className="-tw-mb-[1px] tw-flex tw-items-end tw-gap-2">
          {mentorAvailabilities &&
            mentorAvailabilities.map((mentorAvailability, index) => (
              <MentorAvailabilityTab
                key={mentorAvailability.id}
                title={getLabelForProjectType(mentorAvailability.projectType.value)}
                index={index}
              />
            ))}
        </div>
      </div>
    );
  }

  return <MentorAvailabilitySelect mentorProfileId={mentorProfileId} />;
};

export const AllMentorAvailabilities = ({
  mentorProfileId,
}: {
  mentorProfileId: MentorProfileId;
}) => {
  const { data: mentorAvailabilities, isLoading } = useGetMentorAvailabilitiesQuery({
    mentorProfileId,
  });
  const [selectedMentorAvailabilityIndex, setSelectedMentorAvailabilityIndex] = useState<number>(0);

  const contextValue = useMemo(
    () => ({ selectedMentorAvailabilityIndex, setSelectedMentorAvailabilityIndex }),
    [selectedMentorAvailabilityIndex, setSelectedMentorAvailabilityIndex],
  );

  const selectedMentorAvailability =
    mentorAvailabilities && mentorAvailabilities[selectedMentorAvailabilityIndex];

  if (isLoading) {
    return <Loader />;
  }

  if (!selectedMentorAvailability) {
    return null;
  }

  return (
    <AllMentorAvailabilitiesContext.Provider value={contextValue}>
      <Heading size="h5" alignment="left" className="tw-mb-1">
        Availability
      </Heading>
      <MentorAvailabilitySelector mentorProfileId={mentorProfileId} />
      <Spacer size={2} />
      <MentorAvailability
        mentorProfileId={mentorProfileId}
        mentorAvailability={selectedMentorAvailability}
        skipCapacity={selectedMentorAvailability.projectType.value !== ProjectType.FLAGSHIP}
      />
    </AllMentorAvailabilitiesContext.Provider>
  );
};
