import type { CamelCasedPropertiesDeep } from 'type-fest';

import type {
  CounselorProfileId,
  MentorProfileId,
  ParentProfileId,
  StudentProfileId,
  UserId,
  UUID,
} from './common';
import type {
  InvitationSerializer,
  ProspectiveUserSerializer,
  RoleSerializer,
  SimpleRoleSerializer,
  SimpleUserSerializer,
  SimpleUserWithAdmissionAdvisorDetailsSerializer,
} from './data/backend';
import type { ProductTypes } from './hermes';
import type { Nullable } from './utils';

export enum UserType {
  STUDENT = 'student',
  MENTOR = 'mentor',
  COUNSELOR = 'counselor',
  PARENT = 'parent',
}

export interface SimpleUserWithContactDetails {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  id: UserId;
}

export interface User {
  id: UserId;
  firstName: string;
  lastName: string;
  profilePicture: string;
  timeZone: string;
  userType: UserType;
}

export interface Participant extends User {
  profileId: StudentProfileId | MentorProfileId | CounselorProfileId | ParentProfileId;
  isAdmin: boolean;
}

export interface StudentProfile {
  id: StudentProfileId;
  firstName: string;
  lastName: string;
  email: string;
  parentEmail: Nullable<string>;
  referral: Nullable<string>;
  otherReferral: Nullable<string>;
  podsReferral: string;
  podsReferralExtra: string;
}

export interface LoginData {
  access: string;
  channel: string;
  email: string;
  refresh: string;
  token: string;
  userType: string;
}

export interface UnfinishedRegistration {
  uuid: UUID;
  firstName: string;
  unfinishedRegistration: boolean;
}

export interface LoginUser {
  email: string;
  password: string;
}

export interface MobileLoginData {
  token: string;
  refresh: string;
  userType: string;
}

export enum MobileProvider {
  APPLE = 'apple',
  GOOGLE = 'google',
}

export const enum EMAIL_VALIDATION_RESULT {
  INVALID = 'INVALID',
  RISKY = 'RISKY',
  VALID = 'VALID',
}

export const enum InvitationStatus {
  INVITED = 'invited',
  REGISTERED = 'registered',
  APPLIED = 'applied',
  ACCEPTED = 'accepted',
  ACTIVE = 'active',
  REJECTED = 'rejected',
}

export interface InvitedUser {
  firstName: string;
  email: string;
  profilePicture: string | null;
  status: InvitationStatus;
}

export interface InvitationData {
  inviteeEmail: string;
  inviteeFirstName: string;
  inviteePhoneNumber?: string;
  inviterUserId?: UserId;
  productType?: ProductTypes;
  podSlug?: string;
  ccEmails?: string[];
}

export type Role = CamelCasedPropertiesDeep<RoleSerializer>;
export type SimpleUser = CamelCasedPropertiesDeep<SimpleUserSerializer>;
export type SimpleRole = CamelCasedPropertiesDeep<SimpleRoleSerializer>;
export type Invitation = CamelCasedPropertiesDeep<InvitationSerializer>;
export type ProspectiveUser = CamelCasedPropertiesDeep<ProspectiveUserSerializer>;
export type SimpleUserWithAdmissionAdvisorDetails =
  CamelCasedPropertiesDeep<SimpleUserWithAdmissionAdvisorDetailsSerializer>;
