import type { CamelCasedPropertiesDeep } from 'type-fest';

import type { StringUserType } from '../types/user';

import type { StudentProfile } from './backend';
import type { OptOutFeedbackId, UUID, DateTime, ProjectId } from './common';
import type {
  AdmissionAdvisorModalSerializer,
  CounselorOwnProfileSerializer,
  CountrySerializer,
  DegreeSerializer,
  ExternalStudentProfileOverviewSerializer,
  ExternalStudentProfileSerializer,
  FieldOfStudySerializer,
  FileUploadSerializer,
  FinancialAidDocumentSerializer,
  InstitutionPublicSerializer,
  MentorProfileMinimalSerializer,
  MentorPublicListSerializer,
  MentorSelfProposableSerializer,
  NoteSerializer,
  OwnMentorProfileSerializer,
  ParentOwnProfileSerializer,
  PortfolioItemSerializer,
  PortfolioItemUpdateSerializer,
  ProjectLinkSerializer,
  ProjectOutcomeTypeSerializer,
  ProposedMentorSerializer,
  PublicPortfolioItemSerializer,
  PublicProjectSerializer,
  PublicScholarPageSerializer,
  PublicStudentProfileSerializer,
  ReferralTypeSerializer,
  ScholarGroupSerializer,
  SchoolSerializer,
  StudentAnalyticsSerializer,
  StudentApplicationV2Serializer,
  StudentCollegeChoiceSerializer,
  StudentDetailsSerializer,
  StudentFromMentorRequestSerializer,
  StudentOwnProfileSerializer,
  StudentParentInfoSerializer,
  StudentScholarPageSelectSerializer,
  StudentScholarPageSerializer,
  SymposiumPresenterStatisticsSerializer,
  TagSerializer,
  UserCardSerializer,
  StudentSelectedProductSerializer,
  StudentJourneyMinimalProjectSerializer,
  StudentJourneyProjectSerializer,
  ExternalProfileSerializer,
  PublicDetailedScholarPageSerializer,
  PublicScholarStudentProfileSerializer,
  PublicStudentProfileForCollegeAdmissionSurveySerializer,
  TagCategories,
  HubSpotEmailNotificationSerializer,
  SimpleTagSerializer,
} from './data/marketplace';
import type { ProjectFeeTypes } from './hermes';
import type { StudentApplication } from './studentApplication';
import type { Nullable } from './utils';

export type SelfProposableMentorRequest = CamelCasedPropertiesDeep<MentorSelfProposableSerializer>;

export type selfProposedMentorRequest = CamelCasedPropertiesDeep<MentorSelfProposableSerializer>;

export type OwnMentorProfile = CamelCasedPropertiesDeep<OwnMentorProfileSerializer>;

export type AdmissionAdvisorModal = CamelCasedPropertiesDeep<AdmissionAdvisorModalSerializer>;

export type MentorProfileMinimal = CamelCasedPropertiesDeep<MentorProfileMinimalSerializer>;

export type ExternalStudentProfile = CamelCasedPropertiesDeep<ExternalStudentProfileSerializer>;

export type ProjectOutcomeType = CamelCasedPropertiesDeep<ProjectOutcomeTypeSerializer>;

export type School = CamelCasedPropertiesDeep<SchoolSerializer>;

export type SymposiumPresenterStatistics =
  CamelCasedPropertiesDeep<SymposiumPresenterStatisticsSerializer>;

export type StudentParentInfo = CamelCasedPropertiesDeep<StudentParentInfoSerializer>;

export type StudentApplicationV2 = CamelCasedPropertiesDeep<StudentApplicationV2Serializer>;

export type Country = CamelCasedPropertiesDeep<CountrySerializer>;

export type FinancialAidDocument = CamelCasedPropertiesDeep<FinancialAidDocumentSerializer>;

export type StudentScholarPage = CamelCasedPropertiesDeep<StudentScholarPageSerializer>;

export type PublicDetailedScholarPage =
  CamelCasedPropertiesDeep<PublicDetailedScholarPageSerializer>;

export type PublicScholarStudentProfile =
  CamelCasedPropertiesDeep<PublicScholarStudentProfileSerializer>;

export type StudentScholarPageSelect = CamelCasedPropertiesDeep<StudentScholarPageSelectSerializer>;

export type PortfolioItemStatus = 'created' | 'pending' | 'published' | 'unpublished';

export type SimpleTag = CamelCasedPropertiesDeep<SimpleTagSerializer>;
export type Tag = CamelCasedPropertiesDeep<TagSerializer>;

export type ProjectLink = CamelCasedPropertiesDeep<ProjectLinkSerializer>;

export type StudentOwnProfile = CamelCasedPropertiesDeep<StudentOwnProfileSerializer>;

export type ReferralType = CamelCasedPropertiesDeep<ReferralTypeSerializer>;

export type ParentOwnProfile = CamelCasedPropertiesDeep<ParentOwnProfileSerializer>;

export type CounselorOwnProfile = CamelCasedPropertiesDeep<CounselorOwnProfileSerializer>;

export type MentorPublicList = CamelCasedPropertiesDeep<MentorPublicListSerializer>;

export type PublicProject = CamelCasedPropertiesDeep<PublicProjectSerializer>;

export type PublicPortfolioItem = CamelCasedPropertiesDeep<PublicPortfolioItemSerializer>;

export type PublicStudentProfile = CamelCasedPropertiesDeep<PublicStudentProfileSerializer>;

export type PublicScholarPage = CamelCasedPropertiesDeep<PublicScholarPageSerializer>;

export type InstitutionPublic = CamelCasedPropertiesDeep<InstitutionPublicSerializer>;

export type Degree = CamelCasedPropertiesDeep<DegreeSerializer>;

export type FieldOfStudy = CamelCasedPropertiesDeep<FieldOfStudySerializer>;

export type ScholarGroup = CamelCasedPropertiesDeep<ScholarGroupSerializer>;

export type PortfolioItemUpdate = CamelCasedPropertiesDeep<PortfolioItemUpdateSerializer>;

export type PortfolioItem = CamelCasedPropertiesDeep<PortfolioItemSerializer>;

export type ExternalStudentProfileOverview =
  CamelCasedPropertiesDeep<ExternalStudentProfileOverviewSerializer>;

export type StudentFromMentorRequest = CamelCasedPropertiesDeep<StudentFromMentorRequestSerializer>;

export type StudentAnalytics = CamelCasedPropertiesDeep<StudentAnalyticsSerializer>;

export type StudentDetails = CamelCasedPropertiesDeep<StudentDetailsSerializer>;

export type Note = CamelCasedPropertiesDeep<NoteSerializer>;

export type ProposedMentor = CamelCasedPropertiesDeep<ProposedMentorSerializer>;

export type UserCard = CamelCasedPropertiesDeep<UserCardSerializer>;

export type StudentSelectedProduct = CamelCasedPropertiesDeep<StudentSelectedProductSerializer>;

export type ExternalProfile = CamelCasedPropertiesDeep<ExternalProfileSerializer>;

export type FileUpload = CamelCasedPropertiesDeep<FileUploadSerializer>;

export interface StudentJourney {
  launchpad: StudentJourneyLaunchpadProgram;
  core: StudentJourneyCoreProgram;
  pss: StudentJourneyPSSProgram;
}

export type StudentJourneyMinimalProject =
  CamelCasedPropertiesDeep<StudentJourneyMinimalProjectSerializer>;
export type StudentJourneyProject = CamelCasedPropertiesDeep<StudentJourneyProjectSerializer>;

export interface StudentJourneyLaunchpadProgram {
  projects: StudentJourneyLaunchpadProject[];
  reflection: StudentJourneyLaunchpadReflection;
}

export type StudentJourneyLaunchpadProject =
  | { status: 'under_matching'; subject: Nullable<string> }
  | ({
      status: 'active' | 'completed' | 'terminated';
      subject: string;
    } & StudentJourneyMinimalProject);

export type StudentJourneyLaunchpadReflection =
  | { status: 'inactive'; calendlyUrl: Nullable<string> }
  | ({
      status: 'active' | 'completed' | 'terminated';
    } & StudentJourneyMinimalProject);

export type StudentJourneyCoreProgram =
  | {
      status: 'purchased' | 'under_matching';
      project: null;
    }
  | {
      status: 'active' | 'completed' | 'terminated';
      project: StudentJourneyProject;
    };

export type StudentJourneyPSSProgram =
  | {
      status: 'pitchable' | 'purchasable' | 'purchased' | 'survey_to_be_filled' | 'under_matching';
      project: null;
      coreProjectId: ProjectId;
    }
  | {
      status: 'active' | 'completed' | 'terminated';
      project: StudentJourneyProject;
      coreProjectId: ProjectId;
    };

export interface ProjectLinkUpdateRequest extends Omit<ProjectLink, 'outcomeType'> {
  outcomeType: string | number | undefined;
}

export interface ProjectLinkCreateRequest extends Omit<ProjectLinkUpdateRequest, 'portfolioItem'> {
  portfolioItem: string | number | undefined;
}

export type StudentProfileWithApplication = StudentProfile & {
  profileCompletionStage: number;
  completedApplicationStages: string[];
  studentApplicationId: number;
  showScholarship: boolean;
} & StudentApplication;

export type UserProfileResponse<UserType extends StringUserType> = UserType extends 'student'
  ? StudentOwnProfile
  : UserType extends 'mentor'
    ? OwnMentorProfile
    : UserType extends 'parent'
      ? ParentOwnProfile
      : UserType extends 'counselor'
        ? CounselorOwnProfile
        : never;

export type SearchInstitutions<SearchType, LimitType> = SearchType & LimitType extends
  | string
  | number
  ? { results: InstitutionPublic[] }
  : InstitutionPublic[];

export type StudentUrlRequestParam = 'url_subject' | 'uuid' | 'response' | 'email' | 'pk';

export type MentorRequestProposalType = 'manual' | 'amps' | 'self';

export type MentorPackagePaidOrScholarShip = ProjectFeeTypes;

export type StudentUrlResponse<Params extends Partial<Record<StudentUrlRequestParam, string>>> =
  Params extends {
    uuid: string;
  }
    ? {
        recorded: boolean;
        id: OptOutFeedbackId;
        enrollment?: { project_uuid: UUID; student_pk: number };
      }
    : { recorded: boolean; id: OptOutFeedbackId };

export const NoteTypes = {
  ARCHIVE_REASON: 'archive_reason',
  SCREENING_INTERVIEW: 'screening_interview',
  ADMISSION_ADVISOR: 'admission_advisor',
  FEEDBACK_REQUEST: 'feedback_request',
  COUNSELOR_NOTE: 'counselor_note',
  APPLICATION_REVIEW: 'application_review',
  LAUNCHPAD_MATCHING: 'launchpad_matching',
  STUDENT_OVERVIEW: 'student_overview',
} as const;

export type NoteType = (typeof NoteTypes)[keyof typeof NoteTypes];
export type NoteOrdering = 'created_at' | '-created_at';

export type MentorEngagement =
  | 'unpaid_event_participation'
  | 'mentor_advisory_board'
  | 'mentor_ambassador'
  | 'mentor_interviewer'
  | 'panelist'
  | 'probono_special_initiatives'
  | 'showcasing_specialist'
  | 'symposium_participant';

export type MentorTags =
  | 'Accepted'
  | 'Rejected'
  | 'Ineligible No Workauth'
  | 'Paused By Mentor'
  | 'Deactivated By Mentor'
  | 'Banned By Team'
  | 'Inactive Lost';

export type MentorPerformance = 'green_flag' | 'yellow_flag' | 'red_flag';
export type StudentCollegeChoice = CamelCasedPropertiesDeep<StudentCollegeChoiceSerializer>;

export type PremiumShowcasingSupportMatchingRequestStatuses =
  | 'created'
  | 'pending'
  | 'accepted'
  | 'declined';

export interface PSSMatchingDeclineReason {
  no_good_fit_subject_area?: boolean;
  no_good_fit_showcasing_goal?: boolean;
  no_bandwidth?: boolean;
  timezone_issue?: boolean;
  unavailable_until?: DateTime;
  other?: string;
}

export type FileTypes = 'permanent' | 'temp';

export type ShowcasingSpecialty =
  | 'journal_publications'
  | 'science_fairs'
  | 'conferences_presentations'
  | 'app_development_design'
  | 'websites'
  | 'online_venue'
  | 'self_publishing';

export type PublicStudentProfileForCollegeAdmissionSurvey =
  CamelCasedPropertiesDeep<PublicStudentProfileForCollegeAdmissionSurveySerializer>;

export interface TagCreatePayload {
  name: string;
  category: TagCategories;
}

export type HubSpotEmailNotification = CamelCasedPropertiesDeep<HubSpotEmailNotificationSerializer>;
