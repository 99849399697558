import {
  getBaseAdminUrl,
  getBoshiClientUrl,
  getLandingUrl,
  PathfindersStudentApplicationId,
} from '@polygence/common';
import type {
  CertificateUUID,
  LeadId,
  StudentApplicationId,
  StudentProfileId,
  UserId,
  UUID,
  OpportunityId,
} from '@polygence/common/types/common';
// This file is meant to be used for frontend paths

function login() {
  return '/user/login';
}

function register() {
  return '/user/register/';
}

function registerPasswordless() {
  return '/user/passwordless-signup';
}

function homePage() {
  return '/home';
}

function emailChange() {
  return '/user/email-change';
}

function getAdminPathFor(path: string) {
  return `/administrator${path}`;
}

export function getAdminPathForLead(leadId: LeadId) {
  return getAdminPathFor(`/leads/${leadId}`);
}

function getDashboardPath() {
  return '/dashboard';
}

function getDashboardPathFor(path: string) {
  return `/dashboard${path}`;
}

function dashboardMentorProfile(mentorId: UserId) {
  return `/dashboard/mentor/${mentorId}`;
}

function studentOverview(studentId: number) {
  return getAdminPathFor(`/students/${studentId}`);
}

function counselorStudentOverview(studentId: number) {
  return `/counselor/students/${studentId}`;
}

function studentApplication() {
  return '/student/profile';
}

function studentApplicationById(
  studentProfileId: StudentProfileId,
  applicationId: StudentApplicationId,
) {
  return `/student/${studentProfileId}/application/${applicationId}`;
}

function studentReapplication() {
  return '/student/reapply';
}

function settingsPage() {
  return '/user-settings';
}

function adminMentorProfile(mentorId: number) {
  return getAdminPathFor(`/mentors/${mentorId}`);
}

function hermesProject(projectId: number) {
  return `${getBaseAdminUrl()}/hermes/project/${projectId}/change/`;
}

function adminProjectOverview(projectId: number) {
  return getAdminPathFor(`/project/${projectId}`);
}

function hermesWorkspaceAdmin(workspaceId: number) {
  return `${getBaseAdminUrl()}/hermes/workspace/${workspaceId}/change/`;
}

function hermesWorkspace(id: number) {
  return getDashboardPathFor(`/hermes/${id}`);
}

function assignmentForSession(workspaceId: number, sessionId: number) {
  return `${hermesWorkspace(workspaceId)}/assignment/${sessionId}`;
}

function uploadHermesAttachment(workspaceId: number) {
  return `/hermes/workspaces/${workspaceId}/attachments/`;
}

function removeHermesAttachment() {
  return '/hermes/attachments/';
}

function uploadSessionAssignmentFile() {
  return '/hermes/assignment-solution-files/';
}

function removeSessionAssignmentFile() {
  return '/hermes/assignment-solution-files/';
}

function notionPage(page: string) {
  return `/n/${page}/`;
}

function symposiumPage() {
  return `${getLandingUrl()}/p/symposium`;
}

function symposiumRegistration() {
  return `${getLandingUrl()}/symposium-register`;
}

function programReadMore() {
  return `${getLandingUrl()}/program`;
}

function projectsReadMore(slug: string) {
  return `${getLandingUrl()}/projects/${slug}`;
}

function editStudentApplication(
  studentProfileId: StudentProfileId,
  studentApplicationId: StudentApplicationId,
) {
  return getAdminPathFor(`/student/${studentProfileId}/applications/${studentApplicationId}`);
}

function editStudentProfile(id: number) {
  return `${getBaseAdminUrl()}/marketplace/studentprofile/${id}/change/`;
}

function previewScholarPage(slug: string, previewKey: string) {
  return `${getLandingUrl()}/preview/scholars/${slug}?previewKey=${previewKey}`;
}

function adminPaymentIntent(uuid: string) {
  return getAdminPathFor(`/payment-intent/${uuid}`);
}

function paymentIntent(uuid: string) {
  return `/payment/${uuid}`;
}

function getCounselorStudents() {
  return '/counselor/students';
}

function getCounselorProfile() {
  return '/counselor/profile';
}

function mentorDetails(mentorId: number) {
  return getAdminPathFor(`/mentor-detail/${mentorId}`);
}

function teachingFeedbackWithRecording(projectUuid: string, sessionId: number) {
  return `/teaching/feedback/${projectUuid}/${sessionId}/`;
}

function hubspotContactSearch(email: string) {
  const searchParams = new URLSearchParams();
  searchParams.append('query', email);
  return (
    'https://app.hubspot.com/contacts/7162591/objects/0-1/views/all/list?' + searchParams.toString()
  );
}

function hubspotContact(contactId: string) {
  return `https://app.hubspot.com/contacts/7162591/record/0-1/${contactId}`;
}

function zendeskUserPage(zendeskUserId: string) {
  return `https://polygencehelp.zendesk.com/agent/users/${zendeskUserId}/requested_tickets`;
}

function symposiumHubForMentors() {
  return notionPage('symposium-hub-for-mentors');
}

function symposiumHubForStudents() {
  return notionPage('symposium-hub-for-students');
}

function getResearchHubPageUrl(slug: string) {
  return `${getLandingUrl()}/subjects/${slug}`;
}

function counselorOverview(counselorId: number) {
  return getAdminPathFor(`/counselors/${counselorId}`);
}

function counselorList(searchParams: { companyName?: string }) {
  const queryString = new URLSearchParams(searchParams).toString();
  return getAdminPathFor(queryString ? `/counselors/?${queryString}` : '/counselors/');
}

function studentListForChannel(searchParams: { channel?: string }) {
  const queryString = new URLSearchParams(searchParams).toString();
  return getAdminPathFor(queryString ? `/manage-users/?${queryString}` : '/manage-users/');
}

function finishRegistrationPage(uuid: string) {
  return `/user/finish-signup/${uuid}`;
}

function continueWithGoogle() {
  return '/user/continue-with-google';
}

function manageUsersPage() {
  return getAdminPathFor('/manage-users');
}

function getPathfinderStudentApplication() {
  return '/student/pathfinders-application/';
}

function editPathfinderStudentApplication(applicationId: PathfindersStudentApplicationId) {
  return `/student/pathfinders-application/${applicationId}`;
}

function getPathfinderStudentApplicationConfirmation() {
  return '/student/pathfinders-application-confirmation/';
}

function getUciGatiApplicationPageUrl(uciGatiApplicationUuid: UUID) {
  return `/student/uci-gati-application/${uciGatiApplicationUuid}`;
}

function getUciGatiApplicationPageExternalUrl(uciGatiApplicationUuid: UUID) {
  return `${getBoshiClientUrl()}${getUciGatiApplicationPageUrl(uciGatiApplicationUuid)}`;
}

function getPathfinderStudentApplicationInterim(applicationUuid: UUID) {
  return `/pathfinders-submission/${applicationUuid}`;
}

function getLandingMentorInterestFormPage() {
  return getLandingUrl('/interest-form');
}

function getLandingPodsCohortPage() {
  return getLandingUrl('/pods');
}

export function getPartnerReferralLink(tag: string) {
  return `${getLandingUrl()}/partner/${tag}`;
}

export function getCertificatePage(certificateUuid: CertificateUUID) {
  return `/certificates/${certificateUuid}`;
}

export function feedbackRequestSubmitPage(feedbackRequestUuid: UUID) {
  return `/signed-urls/writing-feedback/${feedbackRequestUuid}`;
}

export function getRecordingUrl(recordingUuid: UUID) {
  return `/recordings/video?uuid=${recordingUuid}`;
}

export function getSingleLeadPageUrl(leadId: LeadId) {
  return `${getBoshiClientUrl()}/administrator/leads/${leadId}`;
}

export function getAdmissionAdvisorMonthlyRevenuesPageUrl(admissionAdvisorUserId: UserId) {
  return getAdminPathFor(`/lead-priority-system/${admissionAdvisorUserId}/payment-details`);
}

export function monthlyRevenueDetailsPage() {
  return getAdminPathFor('/lead-priority-system/monthly-revenue-details');
}

export function admissionAdvisorLeadPriorityPage() {
  return getAdminPathFor('/lead-priority_system');
}

export function admissionAdvisorLeadPriorityAdminPage() {
  return getAdminPathFor('/aalps-admin');
}

function uciGatiApplicationDjango(id: number) {
  return `${getBaseAdminUrl()}/college_credits/ucigatiapplication/${id}/change/`;
}

function uciCohortDjango(id: number) {
  return `${getBaseAdminUrl()}/college_credits/ucicohort/${id}/change/`;
}

function projectIdeaGenerator() {
  return `/project-idea-generator`;
}

const CREATE_OPPORTUNITY_PATH = '/create-showcasing_opportunity';

function updateOpportunityBy(id: OpportunityId) {
  return `${getAdminPathFor(CREATE_OPPORTUNITY_PATH)}?opportunityId=${id}`;
}

function communications(leadId: LeadId) {
  return getAdminPathFor(`/leads/${leadId}/communications/`);
}

export const getPolyPilotPage = () => `/polypilot`;

const urls = {
  paymentIntent,
  adminPaymentIntent,
  login,
  register,
  registerPasswordless,
  getDashboardPath,
  dashboardMentorProfile,
  studentOverview,
  counselorStudentOverview,
  studentApplication,
  studentReapplication,
  adminMentorProfile,
  hermesProject,
  hermesWorkspace,
  adminProjectOverview,
  uploadHermesAttachment,
  removeHermesAttachment,
  notionPage,
  homePage,
  symposiumPage,
  symposiumRegistration,
  programReadMore,
  projectsReadMore,
  assignmentForSession,
  uploadSessionAssignmentFile,
  removeSessionAssignmentFile,
  hermesWorkspaceAdmin,
  editStudentApplication,
  editStudentProfile,
  previewScholarPage,
  getCounselorStudents,
  getCounselorProfile,
  mentorDetails,
  teachingFeedbackWithRecording,
  hubspotContactSearch,
  hubspotContact,
  zendeskUserPage,
  symposiumHubForMentors,
  symposiumHubForStudents,
  getResearchHubPageUrl,
  counselorOverview,
  counselorList,
  studentListForChannel,
  finishRegistrationPage,
  continueWithGoogle,
  manageUsersPage,
  getPathfinderStudentApplication,
  getPathfinderStudentApplicationInterim,
  editPathfinderStudentApplication,
  settingsPage,
  getPathfinderStudentApplicationConfirmation,
  studentApplicationById,
  getLandingMentorInterestFormPage,
  getLandingPodsCohortPage,
  feedbackRequestSubmitPage,
  getRecordingUrl,
  getSingleLeadPageUrl,
  getAdmissionAdvisorMonthlyRevenuesPageUrl,
  monthlyRevenueDetailsPage,
  admissionAdvisorLeadPriorityPage,
  admissionAdvisorLeadPriorityAdminPage,
  getUciGatiApplicationPageUrl,
  getUciGatiApplicationPageExternalUrl,
  uciGatiApplicationDjango,
  uciCohortDjango,
  CREATE_OPPORTUNITY_PATH,
  updateOpportunityBy,
  emailChange,
  getPolyPilotPage,
  projectIdeaGenerator,
  communications,
};

export { urls };
