import { getBaseApiUrl } from '@polygence/common';
import { commonAxios } from '@polygence/common/api/fetch';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { toast } from 'react-toastify';

import { history } from 'src/history';
import { store } from 'src/store';
import { urls } from 'src/urls';
import { getAuthorizationHeader, getImpersonatorAuthorizationHeader } from 'src/utils';
import { isImpersonator } from 'src/utils/auth';
import { navigateTo } from 'src/utils/navigateTo';

const DEFAULT_ERROR_MESSAGE = 'An unexpected error happened.';

const reportUnauthorizedRequestsFromStudentApplication = () => {
  const reportToSentry = window.location.pathname.match(/^\/student\/\d+\/application\/\d+$/);
  if (reportToSentry) {
    const errorMessage =
      'Unauthorized request from student application - probably because the ' +
      "user isn't verified. An application section wrongly demands a verified account; we want students " +
      'to fill out the whole application before activating their account. Check and fix section requirements.';
    Sentry.captureMessage(errorMessage, { level: 'error' });
  }
};

const showApiError = (message: string) => {
  toast.error(message, {
    toastId: 'apiError',
  });
};

commonAxios.interceptors.request.use((config) => {
  const authorizationHeader = getAuthorizationHeader();
  if (authorizationHeader.Authorization) {
    config.headers.setAuthorization(authorizationHeader.Authorization, false);
  }
  config.headers.set(getImpersonatorAuthorizationHeader());
  config.headers.set('X-Polygence-client', 'web');
  return {
    baseURL: getBaseApiUrl(),
    showApiError: true,
    ...config,
  };
});

commonAxios.interceptors.response.use(undefined, (error) => {
  if (!axios.isAxiosError<{ notifyUser?: boolean; detail?: string }>(error)) {
    console.error(error);
    return Promise.reject(error);
  }
  if (error?.response?.status === 500) {
    navigateTo('/error-page');
    return new Promise(() => null);
  }
  if (error?.response?.status === 401) {
    if (!isImpersonator()) {
      localStorage.removeItem('token');
    }
    toast.error('You are not logged in.');
    return new Promise(() => null);
  }
  const user = store.getState().user;
  // @ts-expect-error TODO: extend AxiosRequestConfig
  if (error?.response?.data.notifyUser && error.config?.showApiError) {
    const errorMessage = error.response.data.detail ?? DEFAULT_ERROR_MESSAGE;
    showApiError(errorMessage);
  }

  if (error?.response?.status === 403 && !user.isVerified) {
    reportUnauthorizedRequestsFromStudentApplication();
    history.push('/unverified-unauthorized');
    return new Promise(() => null);
  }
  if (error?.response?.status === 403 && !user.approved && user.utilities.isCounselor) {
    history.push(urls.getCounselorProfile());
    return new Promise(() => null);
  }

  return Promise.reject(error);
});

const internalFetch = commonAxios;
const externalFetch = axios.create();

export { externalFetch, internalFetch };
