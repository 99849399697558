import { commonHooks } from '@polygence/common';
import type { RequiredStudentInformation } from '@polygence/common/types/data/pods';
import { Card, Heading, Text } from '@polygence/components';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Container } from 'react-bootstrap';
import snakecaseKeys from 'snakecase-keys';

import { Loader } from 'src/components/Loader';
import DateWrapper, { DateTimeFormat } from 'src/components/aux/dateWrapper';
import style from 'src/students/Pods/RequiredPodInformation.module.scss';
import { RequiredPodInformationHeader } from 'src/students/Pods/RequiredPodInformationHeader';
import MultiPageForm from 'src/students/form/MultiPageForm';
import { REQUIRED_POD_INFORMATION_SECTIONS } from 'src/students/student-application/required-pod-information-questions';
import { useStudentProfileForApplication } from 'src/students/useStudentProfileForApplication';
import { validateSectionsAsRequired } from 'src/students/validateSectionsAsRequired';
import { getTracker } from 'src/utils/tracking/factory';

interface RequiredInformationProps {
  title: string;
  startDate: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleRequiredInfoSubmit: () => Promise<any>;
  requiredInfo: RequiredStudentInformation;
}

export const RequiredPodInformation = ({
  title,
  startDate,
  handleRequiredInfoSubmit,
  requiredInfo,
}: RequiredInformationProps) => {
  const [isLoading, setIsLoading] = useState(true);

  const {
    studentProfile,
    getStudentProfile,
    updateStudentProfileState,
    partialUpdateStudentProfile,
    getUpdatePayload,
  } = useStudentProfileForApplication();
  const { timeZone } = commonHooks.useCurrentUser();

  useEffect(() => {
    getStudentProfile()
      .catch(console.error)
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formData = useMemo(() => {
    return { ...studentProfile, ...requiredInfo };
  }, [studentProfile, requiredInfo]);

  const initialPage = useMemo(() => {
    return validateSectionsAsRequired(REQUIRED_POD_INFORMATION_SECTIONS, formData);
  }, [formData]);

  const formattedStartDate = new DateWrapper(startDate)
    .setTimezone(timeZone)
    .format(DateTimeFormat.FORMAT_17);

  const handleChange = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
    if (name === 'other') {
      updateStudentProfileState({
        referral: name,
        otherReferral: value,
      });
      return;
    }
    updateStudentProfileState({
      [name]: value,
    });
  };

  const partialUpdateWithTracking = () => {
    const changedFields = getUpdatePayload(studentProfile);
    if ('referral' in changedFields || 'podsReferral' in changedFields) {
      getTracker().track('Pod referral information submitted', {
        ...snakecaseKeys(changedFields),
      });
    }
    return partialUpdateStudentProfile();
  };

  if (isLoading) {
    return <Loader className="mx-auto" />;
  }

  return (
    <div className={style['container']}>
      <Container>
        <div className="py-10">
          <Heading size="h3" className="mb-4">
            {title}
          </Heading>
          <Text size="large" alignment="center">
            {`Starting ${formattedStartDate}`}
          </Text>
        </div>
        <Card className="mx-auto p-8 pb-7" style={{ width: 'max-content' }}>
          <MultiPageForm
            data={formData}
            handleChange={handleChange}
            pages={REQUIRED_POD_INFORMATION_SECTIONS}
            initialPage={initialPage}
            BeforeForm={<RequiredPodInformationHeader />}
            onBeforeNext={partialUpdateWithTracking}
            onBeforeSubmit={partialUpdateWithTracking}
            submitLabel="Proceed to payment"
            onSubmit={handleRequiredInfoSubmit}
            onNext={() => {
              return;
            }}
            onPrevious={() => {
              return;
            }}
            fullScreen={false}
          />
        </Card>
      </Container>
    </div>
  );
};
