import NiceModal, { bootstrapDialog, useModal } from '@ebay/nice-modal-react';
import * as backendApi from '@polygence/common/api/backend';
import { Button, Heading, InputField, Modal, ModalBody, PhoneInput } from '@polygence/components';
import classNames from 'classnames';
import { useState } from 'react';
import { toast } from 'react-toastify';

import styles from 'src/components/Tiles/tilesDashboard.module.scss';

interface InvitationExampleModalProps {
  partnerPaysWorkflow: boolean;
}

export const InvitationExampleModal = NiceModal.create(
  ({ partnerPaysWorkflow }: InvitationExampleModalProps) => {
    const modal = useModal();

    return (
      <Modal closeButton {...bootstrapDialog(modal)}>
        <ModalBody className="mx-auto">
          <img
            className="tw-w-full"
            src={
              partnerPaysWorkflow
                ? 'https://dpl6hyzg28thp.cloudfront.net/media/Resell_Counselor_Invite_Student_Preview.png'
                : 'https://dpl6hyzg28thp.cloudfront.net/media/Counselor_Invite_Student_Preview.png'
            }
            alt="invitation example"
          />
        </ModalBody>
      </Modal>
    );
  },
);

export const StudentInvitationTile = () => {
  const [inviteeEmail, setInviteeEmail] = useState('');
  const [inviteeFirstName, setInviteeFirstName] = useState('');
  const [inviteePhoneNumber, setInviteePhoneNumber] = useState('');
  const [inviteeParentEmail, setInviteeParentEmail] = useState('');

  const handleInvitationSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    backendApi
      .sendInvitation({
        inviteeEmail,
        inviteeFirstName,
        inviteePhoneNumber: inviteePhoneNumber ? inviteePhoneNumber : undefined,
        ccEmails: inviteeParentEmail ? [inviteeParentEmail] : undefined,
      })
      .then(() => {
        setInviteeEmail('');
        setInviteeFirstName('');
        setInviteePhoneNumber('');
        setInviteeParentEmail('');
        toast.success('Invitation sent!');
      })
      .catch(({ response }: { response: { data: { detail: string } } }) => {
        toast.warning(response.data?.detail ?? 'Cannot invite the student.');
      });
  };

  return (
    <div className={classNames(styles['tile'], 'd-flex flex-column justify-content-around p-8')}>
      <Heading size="h4" as="h4" fontWeight="semibold" alignment="left" className="mb-6">
        Invite a student!
      </Heading>
      <form className="d-flex flex-column gap-3" onSubmit={handleInvitationSubmit}>
        <InputField
          id="invitee-first-name"
          name="invitee-first-name"
          type="text"
          label="First name"
          value={inviteeFirstName}
          required
          floating
          onChange={(e) => setInviteeFirstName(e.currentTarget.value)}
        />
        <InputField
          id="invitee-email"
          name="inviteeEmail"
          type="email"
          label="Email Address"
          value={inviteeEmail}
          required
          floating
          onChange={(e) => setInviteeEmail(e.currentTarget.value)}
        />
        <PhoneInput
          shouldValidate={false}
          name="invitee-phone-number"
          type="tel"
          label="Student phone number (Optional)"
          value={inviteePhoneNumber}
          floating
          onChange={(e) => setInviteePhoneNumber(e.currentTarget.value)}
        />
        <InputField
          id="invitee-parent-email"
          name="inviteeParentEmail"
          type="email"
          label="Parent Email Address (Optional)"
          value={inviteeParentEmail}
          floating
          onChange={(e) => setInviteeParentEmail(e.currentTarget.value)}
        />
        <Button type="submit" variant="primary" className="mt-2 text-wrap">
          Send invite with instructions
        </Button>
      </form>
      <Button
        variant="link"
        onClick={() => {
          NiceModal.show(InvitationExampleModal, {
            partnerPaysWorkflow: false,
          }).catch((error) => console.error(error));
        }}
        className="mt-6 text-wrap"
      >
        See an example email
      </Button>
    </div>
  );
};
