import type { CartDiscount, CartItem } from '@polygence/common/types/payment';
import { Nullable } from '@polygence/common/types/utils';

import { CartDiscountWithQuantity } from 'src/payment/types';
import { dayjs } from 'src/utils/dayjsCustom';

export function formatPrice(price: number | string): string {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(+price);
}

export function mergeDiscounts(discounts: CartDiscount[]): CartDiscountWithQuantity[] {
  const uniqueDiscounts = new Map<string, CartDiscountWithQuantity>();

  for (const discount of discounts) {
    const discountKey = `${discount.displayName.toLowerCase()}-${discount.appliedAmountInCents}`;
    const discountQuantity = uniqueDiscounts.get(discountKey)?.quantity ?? 0;

    uniqueDiscounts.set(discountKey, { ...discount, quantity: discountQuantity + 1 });
  }

  return [...uniqueDiscounts.values()];
}

export function getDiscountDisplayName(discount: CartDiscountWithQuantity): string {
  const quantityPrefix = discount.quantity > 1 ? `${discount.quantity} x ` : '';
  const expiresAtSuffix = discount.expiresAt
    ? ` (expires ${dayjs(discount.expiresAt).fromNow()})`
    : '';
  return quantityPrefix + discount.displayName + expiresAtSuffix;
}

export function getDiscountAmountInDollars(discount: CartDiscountWithQuantity): number {
  return (discount.appliedAmountInCents * discount.quantity) / 100;
}

export function getCartItemPriceInDollars(cartItem?: CartItem): Nullable<number> {
  if (!cartItem) {
    return null;
  }

  if (cartItem.manualAmountInCents) {
    return cartItem.manualAmountInCents / 100;
  }

  return cartItem.amountInCents / 100;
}
