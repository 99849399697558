import * as marketplaceApi from '@polygence/common/api/marketplace';
import type { Tag } from '@polygence/common/types/marketplace';
import { Button, Card, InputField, Heading, Spacer, Text } from '@polygence/components';
import { omit } from 'lodash';
import { FormEvent, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';

import MarkdownTextEditor from 'src/components/common/MarkdownTextEditor/MarkdownTextEditor';
import EditableImage from 'src/components/student/ScholarPage/EditableImage';
import { EditableTags } from 'src/components/student/ScholarPage/PortfolioItem/EditableTags';
import EditableProjectLinkList from 'src/components/student/ScholarPage/ProjectLink/EditableProjectLinkList';
import type { ChangingPortfolioItemProps, EditablePortfolioItemProps } from 'src/types/marketplace';

const EditablePortfolioItem = ({
  id,
  title,
  image,
  abstract,
  tags,
  projectLinks,
  outcome,
  mentorReview,
  studentReview,
  onDataChange: handleDataChange,
  setEditing,
}: EditablePortfolioItemProps) => {
  const [data, setData] = useState<ChangingPortfolioItemProps>({
    abstract,
    image,
    projectLinks,
    tags,
    outcome,
    title,
    mentorReview,
    studentReview,
  });
  const [caseStudyTags, setCaseStudyTags] = useState<Tag[]>();
  const smallText = 'small';
  const helperTextColor = 'var(--grayscale-6)';
  const acceptedFileFormat = '.jpg, .jpeg, .webp';

  useEffect(() => {
    marketplaceApi
      .getTags('subject_tag', true)
      .then(({ data }: { data: Tag[] }) => {
        setCaseStudyTags(
          data.map((tag: Tag) => {
            return { ...tag, label: tag.name, value: tag.id };
          }),
        );
      })
      .catch(() => toast.error('Something went wrong.'));
  }, []);

  const validateFields = () => {
    if (data.tags.length < 1) {
      toast.error('You need to select minimum 1 project tag.');
      return false;
    }
    if (data.tags.length > 3) {
      toast.error('Maximum 3 project tags allowed.');
      return false;
    }
    if (data.title.length > 300) {
      toast.error('The project title cannot exceed 300 characters. Please shorten it.');
      return false;
    }
    return true;
  };

  const handleChange = (name: string, value: string | null | Tag[]) => {
    setData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (validateFields()) {
      setEditing(false);
      marketplaceApi
        .updatePortfolioItem(id, {
          // Image is saved when uploaded, no need to upload again.
          ...omit(data, ['image', 'tags']),
          tags: data.tags.map((tag: Tag) => tag.id),
        })
        .then(({ data: res }) => {
          handleDataChange({
            abstract: res.abstract,
            image: res.image,
            // @ts-expect-error type mismatch followup: BSH-6604
            projectLinks: res.projectLinks,
            tags: data.tags,
            outcome: res.outcome,
            title: res.title,
            studentReview: res.studentReview,
            mentorReview: res.mentorReview,
          });
          toast.success('Project updated!');
        })
        .catch(() => {
          toast.error('Something went wrong updating the project');
        });
    }
  };

  return (
    <Card className="p-5 p-md-8">
      <form onSubmit={handleSubmit}>
        <Row className="mb-9">
          <Heading alignment="left" className="col-5 col-sm-8 text-primary" size="h4">
            Project
          </Heading>
        </Row>
        <Row className="mb-10">
          <Heading alignment="left" as="h2" className="mb-6" size="h4">
            Project Title
          </Heading>
          <Text size={smallText} className="mb-5" style={{ color: helperTextColor }}>
            Your project title will appear as your case study title if selected.
          </Text>
          <InputField
            label=""
            name="title"
            onChange={(e) => handleChange('title', e.target.value)}
            value={data.title}
          />
        </Row>
        <Row className="mb-10">
          <Heading alignment="left" as="h2" className="mb-6" size="h4">
            Cover photo
          </Heading>
          <Text size={smallText} className="mb-5" style={{ color: helperTextColor }}>
            This photo will appear on the Projects Page above your Case Study and should represent
            your project.
          </Text>
          <EditableImage
            alt={data.title}
            editing
            uploadUrl={`/api/portfolio-items/${id}/`}
            removeUrl={`/api/portfolio-items/${id}/image`}
            src={data.image}
            inlineImage
            acceptedFileFormat={acceptedFileFormat}
          />
        </Row>
        <Row className="mb-10">
          <Heading alignment="left" as="h2" className="mb-6" size="h4">
            Abstract or project description
          </Heading>
          <Text size={smallText} className="mb-5" style={{ color: helperTextColor }}>
            We will show this text as the “Project Description” for your Case Study. Please give us
            a short summary of your project (no more than two paragraphs).
          </Text>
          <MarkdownTextEditor
            defaultValue={data.abstract}
            onChange={(value) => handleChange('abstract', value)}
            name="abstract"
            value={data.abstract}
          />
        </Row>
        <Row className="mb-10">
          <Heading alignment="left" as="h2" className="mb-6" size="h4">
            Project Tags
          </Heading>
          <Text size={smallText} className="mb-5" style={{ color: helperTextColor }}>
            Help people find your work by providing 1-3 relevant tags/categories for your project..
          </Text>
          {caseStudyTags && (
            <EditableTags
              tags={data.tags}
              handleChange={handleChange}
              caseStudyTags={caseStudyTags}
            />
          )}
        </Row>
        <Row className="mb-10">
          <Col>
            <Heading alignment="left" as="h2" className="mb-6" size="h4">
              Your Polygence Project Review
            </Heading>
            <Text size={smallText} className="mb-5" style={{ color: helperTextColor }}>
              In your project final survey, we asked you about your experience with Polygence and
              your mentor. You can see your response copied below. This is your opportunity to let
              other students know how Polygence and your mentor impacted your final project! Feel
              free to review and edit your response below if needed.
            </Text>
            <Heading alignment="left" as="h3" className="my-6" size="h5">
              How was your experience with your project?
            </Heading>
            <InputField
              type="textarea"
              label=""
              name="studentReview"
              onChange={(e) => handleChange('studentReview', e.target.value)}
              value={data.studentReview}
            />
            <Heading alignment="left" as="h3" className="my-6" size="h5">
              How was your experience with your mentor?
            </Heading>
            <InputField
              type="textarea"
              label=""
              name="mentorReview"
              onChange={(e) => handleChange('mentorReview', e.target.value)}
              value={data.mentorReview}
            />
          </Col>
        </Row>
        <Row className="mb-10">
          <Col>
            <Heading alignment="left" as="h2" className="mb-6" size="h4">
              Project outcome
            </Heading>
            <Text size={smallText} className="mb-5" style={{ color: helperTextColor }}>
              This is where you can list or describe the outcome(s) of your project. Make sure to
              mention all outcomes you created.
            </Text>
            <InputField
              label=""
              name="outcome"
              onChange={(e) => handleChange('outcome', e.target.value)}
              value={data.outcome}
            />
          </Col>
        </Row>

        <Row className="mb-6">
          <Heading alignment="left" as="h2" size="h4" className="mb-6">
            Project links
          </Heading>
          <Text size={smallText} className="mb-5" style={{ color: helperTextColor }}>
            We will show direct link(s) to your project outcome(s) on your Case Study page. Please
            provide us with your project outcome(s), files, URLS, platforms where your project was
            showcased, etc. For example, if you created a podcast and also presented at The
            Symposium of Rising Scholars, please add both outcomes as two separate links.
          </Text>
          <Spacer size={6} />
          {/* @ts-expect-error We should clean-up ProjectLink types in general BSH-6362 */}
          <EditableProjectLinkList projectLinks={data.projectLinks} portfolioItemId={id} />
        </Row>

        <div className="d-flex justify-content-center">
          <Button type="submit" variant="primary" size="sm">
            Save changes
          </Button>
        </div>
      </form>
    </Card>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default EditablePortfolioItem;
