import { commonHooks } from '@polygence/common';
import type { StudentRelatedPod } from '@polygence/common/types/data/pods';
import { PaymentIntentStatuses } from '@polygence/common/types/payment';
import { Collapsible, Heading } from '@polygence/components';
import classNames from 'classnames';
import { Container } from 'react-bootstrap';

import { ActivePodInfoCard } from 'src/students/Pods/ActivePodInfoCard';
import { PendingPodInfoCard } from 'src/students/Pods/PendingPodInfoCard';
import styles from 'src/students/Pods/PodHubPage.module.scss';

export const PodHubPage = ({ pods }: { pods: StudentRelatedPod[] }) => {
  const { firstName } = commonHooks.useCurrentUser();
  const activePods = pods.filter((pod) => pod.paymentIntent?.status === PaymentIntentStatuses.PAID);
  const pendingPods = pods.filter(
    (pod) => pod.paymentIntent?.status !== PaymentIntentStatuses.PAID,
  );

  return (
    <>
      {activePods.length > 0 && (
        <section className={classNames('pt-5 pb-10', styles['activePods'])}>
          <Container>
            <Heading size="h5" alignment="left" className="mb-6">
              {`Welcome to Pods, ${firstName}!`}
            </Heading>
            {activePods.map((entry) => (
              <ActivePodInfoCard key={entry.pod.course.title} pod={entry.pod} />
            ))}
          </Container>
        </section>
      )}
      {pendingPods.length > 0 && (
        <section>
          <Container className="py-5">
            <Collapsible
              summary="Interested in these pods?"
              defaultOpen
              contentClassNames="tw-overflow-visible"
            >
              <hr className={styles['divider']} />
              {pendingPods.map((entry) => (
                <PendingPodInfoCard
                  key={entry.pod.course.title}
                  pod={entry.pod}
                  paymentIntent={entry?.paymentIntent}
                />
              ))}
            </Collapsible>
          </Container>
        </section>
      )}
    </>
  );
};
